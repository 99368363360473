import axios from "axios";
import React from "react";
import { useDispatch } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { PROXY } from "../config";
import { logout } from "../redux/actions/authActions";

function Protected({ user, role }) {

    const nav = useNavigate()

    const dispatch = useDispatch()

    var loggedIn = localStorage.getItem("loggedIn")
    var token = localStorage.getItem("token")
    var user = localStorage.getItem("user")

    if (loggedIn) {
        loggedIn = JSON.parse(localStorage.getItem("loggedIn"))
        if (token) {
            axios.post(`/user/verify`, {}, {
                headers: {
                    token: token
                }
            }).then((res) => {
                const myuser = JSON.parse(user)
                if (myuser.role !== role) {
                    dispatch(logout())
                    // return nav("/login")
                }
            }).catch((err) => {
                dispatch(logout())
                // return nav("/login")
            })
        } else {
            console.log("failed");
        }
    }

    return (
        loggedIn && token ? <Outlet /> : <Navigate to="/login" />
    )
}

export default Protected;