import axios from "axios";

import { ActionTypes } from "../contants/action-types";

export const login = (email, password) => async (dispatch) => {

    try {

        dispatch({
            type: ActionTypes.LOGIN
        })

        const response = await axios.post(`/user/login`, {
            email: email,
            password: password
        });

        dispatch({
            type: ActionTypes.LOGIN_SUCCESS,
            payload: response.data,
        })

    }
    catch (error) {
        dispatch({
            type: ActionTypes.LOGIN_FAILED,
            error: true
        })
    }

};

export const logout = async () => {
    localStorage.removeItem('loggedIn')
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    window.location.reload()
}

export const userData = (id) => {

    // if (id) {
    //     axios.post('/user/userById', {
    //         id: id
    //     }).then((res) => {
    //         console.log(res.data);
    //         return res.data.data
    //     }).catch((err) => {
    //         console.log(err);
    //         return {}
    //     })
    // }

    const data = localStorage.getItem('user')

    if (data) {
        return JSON.parse(data)
    }
    return {}
}