import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useLocation, Link } from 'react-router-dom';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import EngineeringIcon from '@mui/icons-material/Engineering';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import RuleIcon from '@mui/icons-material/Rule';
import { Avatar } from '@mui/material';

import Profile from './Profile'
import { userData } from '../redux/actions/authActions';

const drawerWidth = 240;

function ResponsiveDrawer(props) {
    const { window, children } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const location = useLocation()

    const labelIcons = [
        {
            label: 'Dashboard',
            icon: <LineStyleIcon />,
            route: '/Dashboard',
        },
        {
            label: 'Requested Services',
            icon: <ReportProblemIcon />,
            route: '/Services',
        },
        {
            label: 'Engineers',
            icon: <EngineeringIcon />,
            route: '/Engineers',
        },
        {
            label: 'HelpDesk',
            icon: <DeviceHubIcon />,
            route: '/HelpDesk',
        },
        {
            label: 'Customers',
            icon: <PeopleOutlineIcon />,
            route: '/Customers',
        },
        {
            label: 'Request a Service',
            icon: <AddCircleOutlineIcon />,
            route: '/RequestService',
        },
        {
            label: 'CreateUser',
            icon: <PersonAddIcon />,
            route: '/CreateUser',
        },
        {
            label: 'Manage HelpDesk Rights',
            icon: <RuleIcon />,
            route: '/ManageRights',
        },
    ]

    const [user, setUser] = React.useState({})

    React.useEffect(() => {

        const call = () => {
            setUser(userData())
        }
        call()

    }, [user])

    const drawer = (
        <div style={{
            // background: 'rgb(17, 24, 39)',
            // background: '#0A1929',
            background: '#0f98d9',
            height: '100%',
        }}>
            <Toolbar />
            <Link to='/Account' style={{
                cursor: 'pointer',
                textDecoration: 'none'
            }}>
                <Toolbar sx={{
                    color: 'white',
                    fontSize: 30,
                    margin: 'auto',
                    display: 'flex',
                    flexDirection: 'column'
                }}>
                    <IconButton color="primary" aria-label="upload picture" component="label">
                        <Avatar
                            alt="Remy Sharp"
                            src={user ? user.image : ''}
                            sx={{ width: 56, height: 56 }}
                        />
                    </IconButton>
                    <Typography sx={{
                        color: 'white',
                        fontSize: 16,
                    }}>
                        {user.name}
                    </Typography>
                    <Typography sx={{
                        color: 'white',
                        fontSize: 16,
                    }}>
                        {user.mobile}
                    </Typography>
                    <Typography sx={{
                        color: 'white',
                        fontSize: 16,
                    }}>
                        {user.email}
                    </Typography>
                </Toolbar>
            </Link>
            <Toolbar />
            <Divider sx={{
                // bgcolor: 'gray',
                bgcolor: 'white',
            }} />
            <List sx={{
                // background: '#0A1929',
                background: '#0f98d9',
                // height: '100%',
            }}>
                {labelIcons.map((data, key) => (
                    <Link key={key} to={data.route} style={{
                        textDecoration: 'none',
                    }}>
                        <ListItem disablePadding
                            sx={{
                                background: location.pathname === data.route ? 'rgb(19, 47, 76)' : '',
                            }}
                        >
                            <ListItemButton sx={{
                                '&:hover': {
                                    background: 'rgba(101,108,133,0.8)',
                                },
                                // color: location.pathname === data.route ? 'rgb(102, 178, 255)' : 'rgb(209, 213, 219)',
                                color: location.pathname === data.route ? 'rgb(102, 178, 255)' : 'white',
                            }}>
                                <ListItemIcon sx={{
                                    // color: location.pathname === data.route ? 'rgb(102, 178, 255)' : 'rgb(209, 213, 219)',
                                    color: location.pathname === data.route ? 'rgb(102, 178, 255)' : 'white',                                    
                                }}>
                                    {data.icon}
                                </ListItemIcon>
                                <ListItemText primary={data.label} />
                            </ListItemButton>
                        </ListItem>
                    </Link>
                ))}
            </List>
        </div >
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    ml: { sm: `${drawerWidth}px` },
                    bgcolor: 'white'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        // justifyContent: 'space-between',
                        alignItems: 'center',
                        bgcolor: 'background.paper',
                        width: '100%'
                    }}
                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ mr: 2, display: { sm: 'none' } }}
                        >
                            <MenuIcon sx={{
                                color: 'rgb(107, 114, 128)'
                            }} />
                        </IconButton>
                        {/* <Typography color={'gray'} variant="h6" noWrap component="div">
                        Responsive drawer
                    </Typography> */}
                    </Toolbar>
                    <div style={{
                        position: 'absolute',
                        right: 15
                    }}>
                        <Profile />
                    </div>
                </Box>
            </AppBar>
            <Box
                component="nav"
                sx={{
                    width: {
                        sm: drawerWidth
                    },
                    flexShrink: { sm: 0 },
                }}
                aria-label="mailbox folders"
            >
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        // background: '#0A1929',
                        // height: '100%',
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        // background: '#0A1929',
                        // height: '100%',
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                {children}
            </Box>
        </Box>
    );
}

ResponsiveDrawer.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default ResponsiveDrawer;