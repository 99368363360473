import { ActionTypes } from "../contants/action-types";


const initial = {
    complaints: {},
    error: false,
}

export const complaintsReducer = (state = initial, { type, payload }) => {

    switch (type) {
        case ActionTypes.COMPLAINT_GET:
            return { ...state, error: false };
        case ActionTypes.COMPLAINT_GET_SEUCCESS:
            return { ...state, complaints: payload, error: false };
        case ActionTypes.COMPLAINT_GET_FAILED:
            return { ...state, error: true };
        default:
            return state;
    }
};
