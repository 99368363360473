import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { createTheme } from "@mui/material/styles";
import {
  Alert,
  Badge,
  CircularProgress,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

import axios from "axios";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { storage } from "../firebase";
import { v4 } from "uuid";

const theme = createTheme();

export default function EditUserForm() {
  const [form, setForm] = React.useState({});

  const [imageUrl, setImageUrl] = React.useState(null);

  const [errors, setErrors] = React.useState({});

  const [loading, setLoading] = React.useState(false);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const onImageUpload = async (e) => {
    const file = e.target.files[0];
    await uploadFile(file);
  };

  const uploadFile = (file) => {
    const imageUpload = file;
    const imageRef = ref(storage, `services/${imageUpload.name + v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrl(url);
      });
    });
  };

  const onChange = ({ name, value }) => {
    if (value !== "") {
      if (name === "email") {
        var pattern = /^[a-zA-Z]([a-zA-Z0-9_]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,})$/;

        if (pattern.test(value)) {
          setErrors((prev) => {
            return { ...prev, [name]: null };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, [name]: "Please enter valid email address" };
          });
        }
      }
      if (name === "mobile") {
        var pattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;

        // const num = Number(value)

        if (pattern.test(value)) {
          setErrors((prev) => {
            return { ...prev, [name]: null };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, [name]: "Please enter valid mobile number" };
          });
        }
      } else {
        setErrors((prev) => {
          return { ...prev, [name]: null };
        });
      }
    } else {
      setErrors((prev) => {
        return { ...prev, [name]: "This field is required...." };
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!form.name.trim().length) {
      setErrors((prev) => {
        return { ...prev, name: "Please enter valid name...." };
      });
    }
    if (!form.email.trim().length) {
      setErrors((prev) => {
        return { ...prev, email: "Please enter valid email...." };
      });
    }
    if (!form.mobile.trim().length) {
      setErrors((prev) => {
        return { ...prev, mobile: "Please enter valid mobile number...." };
      });
    }
    if (!form.current_address.trim().length) {
      return setErrors((prev) => {
        return {
          ...prev,
          current_address: "Please enter valid current address....",
        };
      });
    }
    if (!form.permanent_address.trim().length) {
      return setErrors((prev) => {
        return {
          ...prev,
          permanent_address: "Please enter valid permanent address....",
        };
      });
    }
    if (!form.city || !form.city.trim().length) {
      return setErrors((prev) => {
        return {
          ...prev,
          city: "Please enter valid city....",
        };
      });
    }

    if (!form.state || !form.state.trim().length) {
      return setErrors((prev) => {
        return {
          ...prev,
          state: "Please enter valid state....",
        };
      });
    }

    setLoading(true);
    axios
      .post("/user/update", {
        id: form._id,
        name: form.name,
        email: form.email,
        mobile: form.mobile,
        image: imageUrl,
        current_address: form.current_address,
        permanent_address: form.permanent_address,
        city: form.city,
        state: form.state,
      })
      .then((res) => {
        if (res.data.status) {
          localStorage.setItem("user", JSON.stringify(res.data.data));
          // window.location.replace('/dashboard');
          handleClick();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    const call = () => {
      const user = localStorage.getItem("user");

      if (user) {
        setForm(JSON.parse(user));
      }
    };

    call();
  }, []);

  return (
    <div>
      {/* <Typography component="h1" variant="h5">
        Profile
      </Typography>
      <hr style={{
        // margin: auto;
        marginLeft: '0px',
        marginTop: '15px',
        width: '10%',
      }} /> */}

      <Box
        component={Paper}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            autoHideDuration={4000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{ width: "100%" }}
            >
              Profile Updated Successfully....
            </Alert>
          </Snackbar>
        </Stack>

        <Badge
          onChange={onImageUpload}
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          badgeContent={
            // <PhotoCamera />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input hidden accept="image/*" type="file" />
              <PhotoCamera />
            </IconButton>
          }
          style={{
            color: "gray",
            cursor: "pointer",
            margin: "20px 0",
          }}
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="label"
          >
            <input hidden accept="image/*" type="file" />
            <Avatar
              alt="Travis Howard"
              src={imageUrl ? imageUrl : form.image}
              sx={{ width: 70, height: 70 }}
            />
          </IconButton>
        </Badge>

        <Box component="form" sx={{ mt: 3, mx: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                value={form.name}
                autoComplete="given-name"
                name="name"
                required
                fullWidth
                id="name"
                label="name"
                onChange={(e) => {
                  setForm({ ...form, name: e.target.value });
                  onChange({ name: "name", value: e.target.value });
                }}
              />
              {errors.name && (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="error">{errors.name}!</Alert>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                value={form.mobile}
                required
                fullWidth
                id="mobile"
                label="Mobile"
                name="mobile"
                autoComplete="family-name"
                onChange={(e) => {
                  setForm({ ...form, mobile: e.target.value });
                  onChange({ name: "mobile", value: e.target.value });
                }}
              />
              {errors.mobile && (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="error">{errors.mobile}!</Alert>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={form.email}
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={(e) => {
                  setForm({ ...form, email: e.target.value });
                  onChange({ name: "email", value: e.target.value });
                }}
              />
              {errors.email && (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="error">{errors.email}!</Alert>
                </Stack>
              )}
            </Grid>
            {/* <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                        />
                    </Grid> */}
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="city"
                value={form.city}
                required
                fullWidth
                id="city"
                label="City"
                onChange={(e) => {
                  setForm({ ...form, city: e.target.value });
                  onChange({ name: "city", value: e.target.value });
                }}
              />
              {errors.city && (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="error">{errors.city}!</Alert>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                value={form.state}
                fullWidth
                id="state"
                label="State"
                name="state"
                autoComplete="family-name"
                onChange={(e) => {
                  setForm({ ...form, state: e.target.value });
                  onChange({ name: "state", value: e.target.value });
                }}
              />
              {errors.state && (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="error">{errors.state}!</Alert>
                </Stack>
              )}
            </Grid>

            <Grid item xs={12}>
              <TextField
                value={form.current_address}
                required
                fullWidth
                id="current_address"
                label="Current Address"
                name="current_address"
                autoComplete="current_address"
                onChange={(e) => {
                  setForm({ ...form, current_address: e.target.value });
                  onChange({ name: "current_address", value: e.target.value });
                }}
              />
              {errors.current_address && (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="error">{errors.current_address}!</Alert>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={form.permanent_address}
                required
                fullWidth
                id="permanent_address"
                label="Permanent Address"
                name="permanent_address"
                autoComplete="permanent_address"
                onChange={(e) => {
                  setForm({ ...form, permanent_address: e.target.value });
                  onChange({
                    name: "permanent_address",
                    value: e.target.value,
                  });
                }}
              />
              {errors.permanent_address && (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="error">{errors.permanent_address}!</Alert>
                </Stack>
              )}
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                onClick={handleSubmit}
                type="button"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading ? true : false}
              >
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                    }}
                    size={25}
                  />
                ) : null}
                Edit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
}
