import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

function DevelopersContact() {
  return (
    <div className="home_div">
      <Header />

      <div className="alertDiv1">
        <h2>Developer(s) Contact</h2>
        <br />
        <br />
        <div>
          <div>
            <b>Vaagish Hambiria</b>
          </div>
          <div>
            <b>+91 9306045845</b>
          </div>
          <div>
            <b>vaagish64@gmail.com</b>
          </div>
        </div>
        <br />
        <br />
        <div>
          <div>
            <b>Naveen Kumar</b>
          </div>
          <div>
            <b>+91 6301097772</b>
          </div>
          <div>
            <b>naveenkumar00004444@gmail.com</b>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default DevelopersContact;
