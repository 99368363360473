export const ActionTypes = {
  LOGIN: 'LOGIN',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILED: 'LOGIN_FAILED',

  USER: 'USER',
  USER_SUCCESS: 'USER_SUCCESS',
  USER_FAILED: 'USER_FAILED',

  USER_ID: 'USER_ID',
  USER_ID_SUCCESS: 'USER_ID_SUCCESS',
  USER_ID_FAILED: 'USER_ID_FAILED',

  COMPLAINT_GET: 'COMPLAINT_GET',
  COMPLAINT_GET_SEUCCESS: 'COMPLAINT_GET_SEUCCESS',
  COMPLAINT_GET_FAILED: 'COMPLAINT_GET_FAILED',

  RIGHTS_GET: 'RIGHTS_GET',
  RIGHTS_GET_SEUCCESS: 'RIGHTS_GET_SEUCCESS',
  RIGHTS_GET_FAILED: 'RIGHTS_GET_FAILED',
};
