import axios from "axios";

import { ActionTypes } from "../contants/action-types";

export const getComplaints = (status, type, id) => async (dispatch) => {

    try {

        dispatch({
            type: ActionTypes.COMPLAINT_GET
        })

        const response = await axios.post(`/complaint/getallcomplaints`, {
            status: status,
            type: type,
            userId: id,
        });

        dispatch({
            type: ActionTypes.COMPLAINT_GET_SEUCCESS,
            payload: response.data,
        })

    }
    catch (error) {
        dispatch({
            type: ActionTypes.COMPLAINT_GET_FAILED,
            error: true
        })
    }

};
