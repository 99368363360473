import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAqHwSwMS1Us3GhXrZ3zGvcEaEtus_61h4",
  authDomain: "itwaleservices-ac783.firebaseapp.com",
  projectId: "itwaleservices-ac783",
  storageBucket: "itwaleservices-ac783.appspot.com",
  messagingSenderId: "781126786658",
  appId: "1:781126786658:web:46a4904a372aafa59cbb3b",
  measurementId: "G-3SP9T9MLGW",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const storage = getStorage(app);
