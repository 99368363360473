import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import axios from 'axios';
import { PROXY } from '../config'
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../redux/actions/authActions';
import { CircularProgress, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const theme = createTheme();

export default function SignIn() {

    const [errors, setErrors] = React.useState({})

    const [loading, setLoading] = React.useState(false)

    const [show, setShow] = React.useState(false)

    var isLoggedIn = localStorage.getItem("loggedIn")

    const navigate = useNavigate()

    const dispatch = useDispatch()

    const onChange = ({ name, value }) => {

        if (value !== '') {
            if (name === "email") {

                var pattern = /^[a-zA-Z]([a-zA-Z0-9_]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,})$/;

                if (pattern.test(value)) {
                    setErrors(prev => {
                        return { ...prev, [name]: null };
                    });
                } else {
                    setErrors(prev => {
                        return { ...prev, [name]: "Please enter valid email address" };
                    });
                }
            }
            // else if (name == "password") {
            //     if (value.length >= 8) {
            //         setErrors(prev => {
            //             return { ...prev, [name]: null };
            //         });
            //     } else {
            //         setErrors(prev => {
            //             return { ...prev, [name]: "Please enter atleast 8 characters" };
            //         });
            //     }
            // }
            else {
                setErrors(prev => {
                    return { ...prev, [name]: null };
                });
            }
        }
        else {
            setErrors((prev) => {
                return { ...prev, [name]: 'This field is required....' }
            });
        }

    }

    const handleClickShowPassword = () => {
        setShow(!show);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    var currentuser = useSelector((state) => state.currentuser);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        if (!data.get('email').length) {
            setErrors((prev) => {
                return { ...prev, email: 'Please enter valid email address....' }
            });
        }
        if (!data.get('password').length) {
            setErrors((prev) => {
                return { ...prev, password: 'Please enter valid password....' }
            });
        }

        // console.log({
        //     email: data.get('email'),
        //     password: data.get('password'),
        // });

        if (data.get('email').length && data.get('password').length) {
            setLoading(true)
            // axios.post(`${PROXY}/user/login`, {
            //     email: data.get('email'),
            //     password: data.get('password'),
            // }).then((res) => {
            //     if (res.data.status) {                    
            //         const { token, ...user } = res.data.data;
            //         localStorage.setItem('loggedIn', true)
            //         localStorage.setItem('token', res.data.data.token)
            //         localStorage.setItem('user', JSON.stringify(user))
            //         navigate('/Dashboard')
            //     }
            // }).catch((err) => {
            //     console.log("err");
            // })

            dispatch(login(
                data.get('email'),
                data.get('password'),
            ))
        }
    };

    React.useEffect(() => {

        if (isLoggedIn) {
            isLoggedIn = JSON.parse(localStorage.getItem("loggedIn"))
            const u = localStorage.getItem("user")
            const user = JSON.parse(localStorage.getItem("user"))
            if (isLoggedIn && u) {
                // window.location.reload()
                // navigate("/Dashboard")
                if (user.role === 'admin') {
                    window.location.replace('/dashboard')
                }
                if (user.role === 'helpdesk') {
                    window.location.replace('/helpdeskdashboard')
                }
                if (user.role === 'engineer') {
                    window.location.replace('/engineerdashboard')
                }
                if (user.role === 'user') {
                    window.location.replace('/customerdashboard')
                }
            }
        }

    }, [isLoggedIn])

    React.useEffect(() => {

        if (currentuser && currentuser.error) {
            setLoading(false)
        }

    }, [currentuser])

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Login
                    </Typography>
                    {currentuser && currentuser.error &&
                        <Stack sx={{
                            width: '100%',
                            alignItems: 'center'
                        }} spacing={2}>
                            <Alert severity="error">Invalid Credentials.(or).User De-Activated..!</Alert>
                        </Stack>
                    }
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={(e) => {
                                onChange({ name: 'email', value: e.target.value })
                            }}
                        />
                        {errors.email &&
                            <Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert severity="error">{errors.email}!</Alert>
                            </Stack>
                        }
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={show ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            onChange={(e) => {
                                onChange({ name: 'password', value: e.target.value })
                            }}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {show ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                ),
                            }}
                        />
                        {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={show ? 'text' : 'password'}
                                onChange={(e) => {
                                    onChange({ name: 'password', value: e.target.value })
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {true ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl> */}
                        {errors.password &&
                            <Stack sx={{ width: '100%' }} spacing={2}>
                                <Alert severity="error">{errors.password}!</Alert>
                            </Stack>
                        }
                        {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                            disabled={loading ? true : false}
                        >
                            {loading ?
                                <CircularProgress sx={{
                                    color: 'white'
                                }}
                                    size={25}
                                />
                                : null
                            }
                            Submit
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="/forgotPassword" variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/register" variant="body2">
                                    {"Don't have an account? Register"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
}