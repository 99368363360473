import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Autocomplete,
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListSubheader,
  Stack,
} from "@mui/material";

import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";

import { storage } from "../../../firebase";
import { v4 } from "uuid";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../redux/actions/userActions";

import { Country, State, City } from "country-state-city";

const theme = createTheme();

export default function AddComplaint() {
  const usersData = [];

  const types = [
    { label: "Desktop Repair and Maintenance", price: "599" },
    { label: "Laptop Repair and Maintenance", price: "599" },
    { label: "Macbook Repair and Maintenance", price: "999" },
    { label: "IT Consulting", price: "599" },
  ];

  const [imageUrls, setImageUrls] = useState([]);

  const [form, setForm] = React.useState({});

  const [errors, setErrors] = React.useState({});

  const [loading, setLoading] = React.useState(false);

  const [states, setStates] = useState([]);

  const [cities, setCities] = useState([]);

  const [show, setshow] = useState(false);

  const onImageUpload = async (e) => {
    setImageUrls([]);
    const files = e.target.files;
    for (const file of files) {
      await uploadFile(file);
    }
  };

  const uploadFile = (file) => {
    const imageUpload = file;
    const imageRef = ref(storage, `services/${imageUpload.name + v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        // const newArr = [...form.images]
        // newArr.push(url)
        // setForm({ ...form, images: newArr });
        setImageUrls((prev) => [...prev, url]);
      });
    });
  };

  const onChange = ({ name, value }) => {
    if (value !== "") {
      if (name === "mobile") {
        var pattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;

        // const num = Number(value)

        if (pattern.test(value)) {
          setErrors((prev) => {
            return { ...prev, [name]: null };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, [name]: "Please enter valid mobile number" };
          });
        }
      } else {
        setErrors((prev) => {
          return { ...prev, [name]: null };
        });
      }
    } else {
      setErrors((prev) => {
        return { ...prev, [name]: "This field is required...." };
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });

    if (!form.name || !form.name.length) {
      setErrors((prev) => {
        return { ...prev, name: "Please enter valid name...." };
      });
    }
    if (!data.get("description").length) {
      setErrors((prev) => {
        return { ...prev, description: "Please enter valid password...." };
      });
    }
    if (!data.get("model_number").length) {
      setErrors((prev) => {
        return { ...prev, model_number: "Please enter valid model number...." };
      });
    }
    if (!data.get("address").length) {
      setErrors((prev) => {
        return { ...prev, address: "Please enter valid address...." };
      });
    }
    if (!form.mobile || !form.mobile.length) {
      return setErrors((prev) => {
        return { ...prev, mobile: "Please enter valid mobile...." };
      });
    }
    if (!form.userId || !form.userId.length) {
      setErrors((prev) => {
        return { ...prev, userId: "Please select valid User Id...." };
      });
    }
    if (!form.state || !form.state.length) {
      return setErrors((prev) => {
        return { ...prev, state: "Please select valid state...." };
      });
    }
    if (!form.city || !form.city.length) {
      return setErrors((prev) => {
        return { ...prev, city: "Please select valid city...." };
      });
    }

    setLoading(true);

    const d = new Date().toString();
    const i = d.indexOf(" GMT");
    const date = d.slice(0, i);

    const history = [{ date: date, status: "un-assigned" }];

    axios
      .post("/complaint/add", {
        name: form.name,
        description: data.get("description"),
        model_number: data.get("model_number"),
        serial_number: data.get("serial_number"),
        mobile: form.mobile,
        state: form.state,
        city: form.city,
        address: data.get("address"),
        userId: form.userId,
        images: imageUrls,
        history: history,
        price: form.price,
      })
      .then((res) => {
        if (res.data.status) {
          console.log("success");
          window.location.reload();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const dispatch = useDispatch();

  const users = useSelector((state) => state.users.usersData);

  useEffect(() => {
    dispatch(getUsers("user"));
    return () => {};
  }, []);

  useEffect(() => {
    const states = State.getStatesOfCountry("IN");

    const data = [];

    states.forEach((e) => {
      data.push({
        label: e.name,
        code: e.isoCode,
      });
    });
    setStates(data);

    return () => {};
  }, []);

  if (users.status) {
    users.data.forEach((e) => {
      usersData.push({
        label: e.email,
        id: e._id,
        current_address: e.current_address,
        mobile: e.mobile,
      });
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Typography component="h1" variant="h5">
        Request a Service behalf of Customer
      </Typography>
      <hr
        style={{
          // margin: auto;
          marginLeft: "0px",
          marginTop: "15px",
          width: "10%",
        }}
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 2 }}
          >
            <Grid container spacing={2}>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="mobile"
                  label="Mobile"
                  name="mobile"
                  autoComplete="mobile"
                />
              </Grid> */}
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={usersData}
                  onChange={(e, value) => {
                    setForm({
                      ...form,
                      userId: value.id,
                      address: value.current_address,
                      mobile: value.mobile,
                    });
                    onChange({ name: "userId", value: value.id });
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} label="User Id/Email" />
                  )}
                />
                {errors.userId && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.userId}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={types}
                  onChange={(e, value) => {
                    setForm({ ...form, name: value.label, price: value.price });
                    onChange({ name: "name", value: value.label });
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} label="Computer Type" />
                  )}
                />
                {form && form.price && (
                  <Typography
                    sx={{
                      color: "green",
                      mt: 1,
                    }}
                  >
                    ₹ {form.price} including GST (all taxes).
                  </Typography>
                )}
                {errors.name && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.name}!</Alert>
                  </Stack>
                )}
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  autoComplete="name"
                  onChange={(e) => {
                    onChange({ name: 'name', value: e.target.value })
                  }}
                />
                {errors.name &&
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="error">{errors.name}!</Alert>
                  </Stack>
                }
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="description"
                  label="Description"
                  name="description"
                  autoComplete="description"
                  multiline
                  maxRows={4}
                  onChange={(e) => {
                    onChange({ name: "description", value: e.target.value });
                  }}
                />
                {errors.description && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.description}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="model_number"
                  label="Model Number"
                  name="model_number"
                  autoComplete="model_number"
                  onChange={(e) => {
                    onChange({ name: "model_number", value: e.target.value });
                  }}
                />
                {errors.model_number && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.model_number}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="serial_number"
                  label="Serial Number"
                  name="serial_number"
                  autoComplete="serial_number"
                  onChange={(e) => {
                    onChange({ name: "serial_number", value: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  value={form.mobile}
                  id="mobile"
                  label="Mobile"
                  name="mobile"
                  autoComplete="mobile"
                  multiline
                  maxRows={4}
                  onChange={(e) => {
                    setForm({ ...form, mobile: e.target.value });
                    onChange({ name: "mobile", value: e.target.value });
                  }}
                />
                {errors.mobile && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.mobile}!</Alert>
                  </Stack>
                )}
              </Grid>
              {/* <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={states}
                  onChange={(e, value) => {
                    setForm({ ...form, state: value.label });
                    onChange({ name: "state", value: value.label });
                    const cities = City.getCitiesOfState("IN", value.code);
                    const data = [
                      {
                        label: "Not in the List",
                      },
                    ];
                    cities.forEach((e) => {
                      data.push({
                        label: e.name,
                      });
                    });
                    setCities(data);
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} label="State" />
                  )}
                />
                {errors.state && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.state}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={cities}
                  onChange={(e, value) => {
                    if (value.label !== "Not in the List") {
                      setForm({ ...form, city: value.label });
                      onChange({ name: "city", value: value.label });
                      setshow(false);
                    } else {
                      setshow(true);
                      setForm({ ...form, city: "" });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} label="City" />
                  )}
                />
                {errors.city && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.city}!</Alert>
                  </Stack>
                )}
              </Grid> */}
              {show && (
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    // value={form.city}
                    id="city"
                    label="City"
                    name="city"
                    autoComplete="city"
                    multiline
                    maxRows={4}
                    onChange={(e) => {
                      setForm({ ...form, city: e.target.value });
                      onChange({ name: "city", value: e.target.value });
                    }}
                  />
                  {errors.city && (
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      <Alert severity="error">{errors.city}!</Alert>
                    </Stack>
                  )}
                </Grid>
              )}

<Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={[
                    { label: "Delhi" },
                    { label: "Haryana" },
                    { label: "UP" },
                  ]}
                  onChange={(e, value) => {
                    setForm({ ...form, state: value.label });
                    onChange({ name: "state", value: value.label });
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} label="State" />
                  )}
                />
                {errors.state && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.state}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={[
                    { label: "Delhi" },
                    { label: "Faridabad" },
                    { label: "Gurugram" },
                    { label: "Noida" },
                  ]}
                  onChange={(e, value) => {
                    setForm({ ...form, city: value.label });
                    onChange({ name: "city", value: value.label });
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} label="City" />
                  )}
                />
                {errors.city && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.city}!</Alert>
                  </Stack>
                )}
              </Grid>
              
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="address"
                  label="Address"
                  name="address"
                  autoComplete="address"
                  multiline
                  maxRows={4}
                  value={form.address}
                  onChange={(e) => {
                    onChange({ name: "address", value: e.target.value });
                  }}
                />
                {errors.address && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.address}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography>Upload images</Typography>
                <br />
                <Button variant="contained" component="label">
                  Upload
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={onImageUpload}
                  />
                </Button>
                <ImageList sx={{ Width: 330, maxHeight: 350 }}>
                  {/* <ImageListItem key="Subheader" cols={2}>
                    <ListSubheader component="div">Uploaded Images</ListSubheader>
                  </ImageListItem> */}
                  {imageUrls.map((item, key) => (
                    <ImageListItem key={key}>
                      <img
                        src={item}
                        // srcSet={`https://www.w3schools.com/css/img_forest.jpg`}
                        alt={"image"}
                        loading="lazy"
                      />
                      <ImageListItemBar
                        title={"Remove"}
                        // subtitle={item.author}
                        sx={{
                          height: 30,
                          cursor: "pointer",
                          background: "gray",
                          color: "red",
                          fontWeight: "700",
                        }}
                        onClick={() => {
                          const newArr = [...imageUrls];
                          newArr.splice(key, 1);
                          setImageUrls(newArr);
                        }}
                        actionIcon={
                          <IconButton
                            sx={{ color: "orangered" }}
                            // aria-label={`info about ${item.title}`}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading ? true : false}
            >
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                  }}
                  size={25}
                />
              ) : null}
              Submit
            </Button>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </Container>
    </ThemeProvider>
  );
}
