import emailjs from "@emailjs/browser";

const SendCallRequestEmail = async (form) => {
  emailjs
    .sendForm(
      "service_ffewlps",
      "template_ehkvvik",
      form.current,
      "FoNLQBBKpnyz57Fk4"
    )
    .then(
      (result) => {
        console.log(result.text);        
      },
      (error) => {
        console.log(error.text);        
      }
    );
};

const SendResetEmail = async (email, link) => {

  var params = {
    email: email,
    link: link
};

  emailjs
    .send(
      "service_ffewlps",
      "template_skyy4yh",
      params,
      "FoNLQBBKpnyz57Fk4"
    )
    .then(
      (result) => {
        console.log(result.text);        
      },
      (error) => {
        console.log(error.text);        
      }
    );
};

export { SendCallRequestEmail, SendResetEmail };
