import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Autocomplete,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListSubheader,
  Modal,
  Stack,
} from "@mui/material";

import {
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  list,
} from "firebase/storage";

import { storage } from "../../../firebase";
import { v4 } from "uuid";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../../redux/actions/userActions";
// import { userData } from "../../../redux/actions/authActions";

import logo from "../../../assets/images/logo.png";

import { Country, State, City } from "country-state-city";
import PDF from "../../../pages/Pdf";

const theme = createTheme();

export default function AddComplaint() {
  const usersData = [];

  const types = [
    { label: "Desktop Repair and Maintenance", price: "599" },
    { label: "Laptop Repair and Maintenance", price: "599" },
    { label: "Macbook Repair and Maintenance", price: "999" },
    { label: "IT Consulting", price: "599" },
  ];

  const userId =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user"))._id;

  const mobile =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).mobile;

  const address =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")).current_address;

  const [imageUrls, setImageUrls] = useState([]);

  const [form, setForm] = React.useState({
    userId: userId,
    mobile: mobile,
    address: address,
  });

  const [errors, setErrors] = React.useState({});

  const [loading, setLoading] = React.useState(false);

  const [user, setUser] = React.useState({});

  const [states, setStates] = useState([]);

  const [cities, setCities] = useState([]);

  const [show, setshow] = useState(false);

  const onImageUpload = async (e) => {
    setImageUrls([]);
    const files = e.target.files;
    for (const file of files) {
      await uploadFile(file);
    }
  };

  const uploadFile = (file) => {
    const imageUpload = file;
    const imageRef = ref(storage, `services/${imageUpload.name + v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        // const newArr = [...form.images]
        // newArr.push(url)
        // setForm({ ...form, images: newArr });
        setImageUrls((prev) => [...prev, url]);
      });
    });
  };

  const onChange = ({ name, value }) => {
    if (value !== "") {
      if (name === "mobile") {
        var pattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;

        // const num = Number(value)

        if (pattern.test(value)) {
          setErrors((prev) => {
            return { ...prev, [name]: null };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, [name]: "Please enter valid mobile number" };
          });
        }
      } else {
        setErrors((prev) => {
          return { ...prev, [name]: null };
        });
      }
    } else {
      setErrors((prev) => {
        return { ...prev, [name]: "This field is required...." };
      });
    }
  };

  const handleSubmit = () => {
    // event.preventDefault();
    // const data = new FormData(event.currentTarget);
    // console.log({
    //   email: data.get('email'),
    //   password: data.get('password'),
    // });

    if (!form.name || !form.name.length) {
      setErrors((prev) => {
        return { ...prev, name: "Please enter valid name...." };
      });
    }
    if (!form.description || !form.description.length) {
      setErrors((prev) => {
        return { ...prev, description: "Please enter valid description...." };
      });
    }
    if (!form.model_number || !form.model_number.length) {
      setErrors((prev) => {
        return { ...prev, model_number: "Please enter valid model number...." };
      });
    }
    if (!form.mobile || !form.mobile.length) {
      setErrors((prev) => {
        return { ...prev, address: "Please enter valid mobile...." };
      });
    }
    if (!form.address || !form.address.length) {
      setErrors((prev) => {
        return { ...prev, address: "Please enter valid address...." };
      });
    }
    if (!form.userId || !form.userId.length) {
      setErrors((prev) => {
        return { ...prev, userId: "Please select valid User Id...." };
      });
    }
    if (!form.state || !form.state.length) {
      return setErrors((prev) => {
        return { ...prev, state: "Please select valid state...." };
      });
    }
    if (!form.city || !form.city.length) {
      return setErrors((prev) => {
        return { ...prev, city: "Please select valid city...." };
      });
    }

    // addC();
    handleOpen();
  };

  const addC = () => {
    setLoading(true);
    const d = new Date().toString();
    const i = d.indexOf(" GMT");
    const date = d.slice(0, i);

    // const history = [{ date: date, status: "pending" }];
    const history = [{ date: date, status: "un-assigned" }];

    const payments = [{ date: date, status: "paid", price: form.price }];

    axios
      .post("/complaint/add", {
        name: form.name,
        description: form.description,
        model_number: form.model_number,
        serial_number: form.serial_number,
        mobile: form.mobile,
        state: form.state,
        city: form.city,
        address: form.address,
        userId: form.userId,
        images: imageUrls,
        history: history,
        price: form.price,
        payments: [],
      })
      .then((res) => {
        if (res.data.status) {
          // console.log("success");
          // alert(
          //   "Successfully requested a service, you can pay after the service."
          // );
          // window.location.reload();
          setForm(res.data.data);
          setOpen1(true);
          handleClose();
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const initPayment = (data) => {
    const options = {
      key: "rzp_test_HtVe35bnD87Ad6",
      amount: data.amount,
      currency: data.currency,
      name: user.name,
      description: form.name,
      image: logo,
      // order_id: data.id,
      handler: async (response) => {
        try {
          const { data } = await axios.post("/payment/verify", response);
          if (data.status) {
            addC();
          }
        } catch (error) {
          console.log(error);
        }
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.mobile,
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handlePayment = async () => {
    handleSubmit();

    if (
      form.name.length &&
      form.description.length &&
      form.model_number.length &&
      form.address.length &&
      form.mobile.length
    ) {
      try {
        const { data } = await axios.post("/payment/orders", {
          amount: form.price,
        });
        initPayment(data.data);
      } catch (error) {
        console.log(error);
      }
    } else {
      console.log("Hello");
    }
  };

  const dispatch = useDispatch();

  const users = useSelector((state) => state.users.usersData);

  useEffect(() => {
    dispatch(getUsers("user"));

    // setUser(userData());

    return () => {};
  }, []);

  useEffect(() => {
    const states = State.getStatesOfCountry("IN");

    const data = [];

    states.forEach((e) => {
      data.push({
        label: e.name,
        code: e.isoCode,
        current_address: e.current_address,
        mobile: e.mobile,
      });
    });
    setStates(data);

    return () => {};
  }, []);

  if (users.status) {
    users.data.forEach((element) => {
      usersData.push({ label: element.email, id: element._id });
    });
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    // window.location.reload();
  };

  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => {
    addC();
  };
  const handleClose1 = () => {
    setOpen1(false);
    window.location.reload();
  };

  const fileUser = localStorage.getItem("user");

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Pay After The Service"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    color: "#1976d2",
                  }}
                >
                  Computer Type
                </div>
                <span>{form.name}</span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    color: "#1976d2",
                  }}
                >
                  Modal Number
                </div>
                <span>{form.model_number}</span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    color: "#1976d2",
                  }}
                >
                  Serial Number
                </div>
                <span>{form.serial_number}</span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    color: "#1976d2",
                  }}
                >
                  Mobile
                </div>
                <span>{form.mobile}</span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    color: "#1976d2",
                  }}
                >
                  State
                </div>
                <span>{form.state}</span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    color: "#1976d2",
                  }}
                >
                  City
                </div>
                <span>{form.city}</span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    color: "#1976d2",
                  }}
                >
                  Address
                </div>
                <span>{form.address}</span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    color: "#1976d2",
                  }}
                >
                  Description
                </div>
                <span>{form.description}</span>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div
                  style={{
                    color: "#1976d2",
                  }}
                >
                  Images
                </div>
                <ImageList sx={{ Width: 330, maxHeight: 350 }}>
                  {imageUrls.map((item, key) => (
                    <ImageListItem key={key}>
                      <img src={item} alt={"image"} loading="lazy" />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Grid>
            </Grid>
          </DialogContentText>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
        </DialogContent>
        <DialogActions>
          {/* <div>
            {form && form.history && (
              <PDF data={form} user={JSON.parse(fileUser)} />
            )}
          </div> */}
          <Button onClick={handleOpen1}>Done</Button>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={open1}
        onClose={handleClose1}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Successfully requested a service, you can pay after the service. You
            can download the invoice.
          </DialogContentText>
          {/* <Button onClick={handleClose1}>Disagree</Button> */}
        </DialogContent>
        <DialogActions>
          <div>
            {form && form.history && (
              <PDF data={form} user={JSON.parse(fileUser)} />
            )}
          </div>
          <Button onClick={handleClose1} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Typography component="h1" variant="h5">
        Request a Service
      </Typography>
      <hr
        style={{
          // margin: auto;
          marginLeft: "0px",
          marginTop: "15px",
          width: "10%",
        }}
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ mt: 2 }}>
            <Grid container spacing={2}>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="mobile"
                  label="Mobile"
                  name="mobile"
                  autoComplete="mobile"
                />
              </Grid> */}
              {/* <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={usersData}
                  onChange={(e, value) => {
                    setForm({ ...form, userId: value.id })
                    onChange({ name: 'userId', value: value.id })
                  }}
                  renderInput={(params) => <TextField required {...params} label="User Id/Email" />}
                />
                {errors.userId &&
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="error">{errors.userId}!</Alert>
                  </Stack>
                }
              </Grid> */}
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={types}
                  onChange={(e, value) => {
                    setForm({ ...form, name: value.label, price: value.price });
                    onChange({ name: "name", value: value.label });
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} label="Computer Type" />
                  )}
                />
                {form && form.price && (
                  <Typography
                    sx={{
                      color: "green",
                      mt: 1,
                    }}
                  >
                    ₹ {form.price} including GST (all taxes).
                  </Typography>
                )}
                {errors.name && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.name}!</Alert>
                  </Stack>
                )}
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  autoComplete="name"
                  onChange={(e) => {
                    onChange({ name: 'name', value: e.target.value })
                  }}
                />
                {errors.name &&
                  <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="error">{errors.name}!</Alert>
                  </Stack>
                }
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="description"
                  label="Description"
                  name="description"
                  autoComplete="description"
                  multiline
                  maxRows={4}
                  onChange={(e) => {
                    setForm({ ...form, description: e.target.value });
                    onChange({ name: "description", value: e.target.value });
                  }}
                />
                {errors.description && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.description}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="model_number"
                  label="Model Number"
                  name="model_number"
                  autoComplete="model_number"
                  onChange={(e) => {
                    setForm({ ...form, model_number: e.target.value });
                    onChange({ name: "model_number", value: e.target.value });
                  }}
                />
                {errors.model_number && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.model_number}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="serial_number"
                  label="Serial Number"
                  name="serial_number"
                  autoComplete="serial_number"
                  onChange={(e) => {
                    setForm({ ...form, serial_number: e.target.value });
                    onChange({ name: "serial_number", value: e.target.value });
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="mobile"
                  label="Mobile"
                  name="mobile"
                  autoComplete="mobile"
                  multiline
                  maxRows={4}
                  onChange={(e) => {
                    setForm({ ...form, mobile: e.target.value });
                    onChange({ name: "mobile", value: e.target.value });
                  }}
                  value={form.mobile}
                />
                {errors.mobile && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.mobile}!</Alert>
                  </Stack>
                )}
              </Grid>
              {/* <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={states}
                  onChange={(e, value) => {
                    setForm({ ...form, state: value.label });
                    onChange({ name: "state", value: value.label });
                    const cities = City.getCitiesOfState("IN", value.code);
                    const data = [
                      {
                        label: "Not in the List",
                      },
                    ];
                    cities.forEach((e) => {
                      data.push({
                        label: e.name,
                      });
                    });
                    setCities(data);
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} label="State" />
                  )}
                />
                {errors.state && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.state}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={cities}
                  onChange={(e, value) => {
                    if (value.label !== "Not in the List") {
                      setForm({ ...form, city: value.label });
                      onChange({ name: "city", value: value.label });
                      setshow(false);
                    } else {
                      setshow(true);
                      setForm({ ...form, city: "" });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} label="City" />
                  )}
                />
                {errors.city && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.city}!</Alert>
                  </Stack>
                )}
              </Grid> */}
              {show && (
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    // value={form.city}
                    id="city"
                    label="City"
                    name="city"
                    autoComplete="city"
                    multiline
                    maxRows={4}
                    onChange={(e) => {
                      setForm({ ...form, city: e.target.value });
                      onChange({ name: "city", value: e.target.value });
                    }}
                  />
                  {errors.city && (
                    <Stack sx={{ width: "100%" }} spacing={2}>
                      <Alert severity="error">{errors.city}!</Alert>
                    </Stack>
                  )}
                </Grid>
              )}

              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={[
                    { label: "Delhi" },
                    { label: "Haryana" },
                    { label: "UP" },
                  ]}
                  onChange={(e, value) => {
                    setForm({ ...form, state: value.label });
                    onChange({ name: "state", value: value.label });
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} label="State" />
                  )}
                />
                {errors.state && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.state}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={[
                    { label: "Delhi" },
                    { label: "Faridabad" },
                    { label: "Gurugram" },
                    { label: "Noida" },
                  ]}
                  onChange={(e, value) => {
                    setForm({ ...form, city: value.label });
                    onChange({ name: "city", value: value.label });
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} label="City" />
                  )}
                />
                {errors.city && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.city}!</Alert>
                  </Stack>
                )}
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="address"
                  label="Address"
                  name="address"
                  autoComplete="address"
                  multiline
                  maxRows={4}
                  onChange={(e) => {
                    setForm({ ...form, address: e.target.value });
                    onChange({ name: "address", value: e.target.value });
                  }}
                  value={form.address}
                />
                {errors.address && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.address}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <Typography>Upload images</Typography>
                <br />
                <Button variant="contained" component="label">
                  Upload
                  <input
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    onChange={onImageUpload}
                  />
                </Button>
                <ImageList sx={{ Width: 330, maxHeight: 350 }}>
                  {/* <ImageListItem key="Subheader" cols={2}>
                    <ListSubheader component="div">Uploaded Images</ListSubheader>
                  </ImageListItem> */}
                  {imageUrls.map((item, key) => (
                    <ImageListItem key={key}>
                      <img
                        src={item}
                        // srcSet={`https://www.w3schools.com/css/img_forest.jpg`}
                        alt={"image"}
                        loading="lazy"
                      />
                      <ImageListItemBar
                        title={"Remove"}
                        // subtitle={item.author}
                        sx={{
                          height: 30,
                          cursor: "pointer",
                          background: "gray",
                          color: "red",
                          fontWeight: "700",
                        }}
                        onClick={() => {
                          const newArr = [...imageUrls];
                          newArr.splice(key, 1);
                          setImageUrls(newArr);
                        }}
                        actionIcon={
                          <IconButton
                            sx={{ color: "orangered" }}
                            // aria-label={`info about ${item.title}`}
                          >
                            <DeleteIcon />
                          </IconButton>
                        }
                      />
                    </ImageListItem>
                  ))}
                </ImageList>
              </Grid>
            </Grid>
            <Button
              onClick={handleSubmit}
              type="button"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading ? true : false}
            >
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                  }}
                  size={25}
                />
              ) : null}
              Submit
            </Button>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </Container>
    </ThemeProvider>
  );
}
