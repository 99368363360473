import { useRef } from "react";
import ReactToPrint from "react-to-print";
import "../styles/pdf.css";
import logo from "../assets/images/logo.png";

import convert from "number-to-words";
import { Button } from "@mui/material";

function PDF({ data, user }) {
  const componentRef = useRef();

  return (
    <div className="App">
      <ReactToPrint
        trigger={() => <Button>Invoice!</Button>}
        content={() => componentRef.current}
      />
      {/* <ComponentToPrint ref={componentRef} /> */}
      <div className="file">
        <div className="invoice-box" ref={componentRef}>
          <table cellpadding="0" cellspacing="0">
            <tr className="top_rw">
              <td
                colspan="2"
                style={{
                  paddingLeft: "30px",
                }}
              >
                <h2>
                  <span>Tax invoice</span>
                </h2>
                <span>
                  Invoice No: ITWALESB
                  {data.id ? data.id.slice(-6) : data._id.slice(-6)}
                </span>
                <br />
                <span>Invoice Date: {data.history[0].date}</span>
                <br />
                <span>GSTIN/UIN: 06AAFCJ7633L1Z8</span>
                <br />
                <br />
              </td>
              <td
                style={
                  {
                    // display: "flex",
                    // flexDirection: "column",
                    // alignItems: "flex-end",
                    // paddingRight: "30px",
                    // background: "red",
                    // height: '100%'
                  }
                }
              >
                <img src={logo} width={100} />
                {/* <span>GSTIN/UIN: 06AAFCJ7633L1Z8</span> */}
                {/* <span>PAN: 4413141</span> */}
              </td>
            </tr>
            <tr className="top">
              <td colspan="3">
                <table
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <tr>
                    <td
                      style={
                        {
                          // width: "50%"
                        }
                      }
                    >
                      <b>
                        <span>Service Provider Address</span>
                      </b>
                      <br />
                      JITW SYSTEMS PVT LTD First Floor, <br />
                      B-401, Neelam Road, Nehru Ground, <br />
                      New Industrial Township, Faridabad, Haryana.
                      <br />
                      <b>
                        <span>E-Mail : support@jitwsystems.com</span>
                      </b>
                      <br />
                    </td>
                    <td
                      style={
                        {
                          // width: "50%",
                        }
                      }
                    >
                      <b>
                        <span>Customer Address</span>
                      </b>
                      <br />
                      {user && user.name},
                      <br />
                      {data.address},
                      <br />
                      {data.city}, {data.state},
                      <br />
                      {data.mobile},
                      <br />
                      {user && user.email}.
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <td colspan="3">
              <table cellspacing="0px" cellpadding="2px">
                <tr className="heading">
                  <td>SERVICE TYPE</td>
                  {/* <td>PRICE (INR)</td> */}
                  {/* <td>TAX RATE & TYPE</td> */}
                  {/* <td>TAX AMOUNT (INR)</td> */}
                  <td>TOTAL AMOUNT (Including GST/IGST)</td>
                </tr>
                <tr className="item">
                  <td>{data.name}</td>
                  {/* <td>
                    {Number(data.price) -
                      Math.ceil((Number(data.price) * 18) / 100)}
                  </td> */}
                  {/* <td>18% GST/IGST</td> */}
                  {/* <td>{Math.ceil((Number(data.price) * 18) / 100)}</td> */}
                  <td>
                    INR {data.price}
                  </td>
                </tr>
              </table>
            </td>
            <tr className="total">
              <td colspan="3" align="right">
                <br />
                <span>
                  Total Amount in Words :{" "}
                  <b>
                    {convert
                      .toWords(
                        Number(data.price) +
                          Math.ceil((Number(data.price) * 18) / 100)
                      )
                      .toLocaleUpperCase()}
                  </b>
                </span>
                <br />
              </td>
            </tr>
          </table>
          <h5
            style={{
              textAlign: "center",
            }}
          >
            *This is computer generated invoice.
          </h5>
        </div>
      </div>
    </div>
  );
}

export default PDF;
