import React from "react";
import logo from "../assets/images/logo.png";

function Footer() {
  return (
    <div>
      <footer>
        <div className="footer-top">
          <div className="container">
            <div className="footer-brand">
              <a
                href="#"
                className="logo"
                style={{
                  width: "60px",
                  height: "60px",
                }}
              >
                <img
                  src={logo}
                  alt="Landio logo"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </a>

              <p className="footer-text">
                IT WALE, The most trusted laptop, desktop, and many more
                services are available .
              </p>

              <ul className="social-list">
                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-facebook"></ion-icon>
                  </a>
                </li>

                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-twitter"></ion-icon>
                  </a>
                </li>

                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-instagram"></ion-icon>
                  </a>
                </li>

                <li>
                  <a href="#" className="social-link">
                    <ion-icon name="logo-linkedin"></ion-icon>
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer-link-box">
              <ul className="footer-list">
                <li>
                  <p className="footer-item-title">GO TO LINKS</p>
                </li>

                <li>
                  <a href="/#hero" className="footer-link">
                    Home
                  </a>
                </li>

                <li>
                  <a href="/#about" className="footer-link">
                    Services
                  </a>
                </li>

                <li>
                  <a href="/#features" className="footer-link">
                    How it works
                  </a>
                </li>

                <li>
                  <a href="/#contact" className="footer-link">
                    Contact Us
                  </a>
                </li>

                {/* <li>
                                    <a href="#" className="footer-link">Mission</a>
                                </li> */}
              </ul>

              <ul className="footer-list">
                <li>
                  <p className="footer-item-title">CUSTOMERS</p>
                </li>

                <li>
                  <a href="/register" className="footer-link">
                    Register
                  </a>
                </li>

                <li>
                  <a href="http://103.158.65.119/#/auth/login" target="_blank" className="footer-link">
                    Login
                  </a>
                </li>

                {/* <li>
                                    <a href="#" className="footer-link">Popular</a>
                                </li>

                                <li>
                                    <a href="#" className="footer-link">Customers</a>
                                </li>

                                <li>
                                    <a href="#" className="footer-link">Features</a>
                                </li> */}
              </ul>

              {/* <ul className="footer-list">

                                <li>
                                    <p className="footer-item-title">SUPPORT</p>
                                </li>

                                <li>
                                    <a href="#" className="footer-link">Developers</a>
                                </li>

                                <li>
                                    <a href="#" className="footer-link">Support</a>
                                </li>

                                <li>
                                    <a href="#" className="footer-link">Customer Service</a>
                                </li>

                                <li>
                                    <a href="#" className="footer-link">Get Started</a>
                                </li>

                                <li>
                                    <a href="#" className="footer-link">Guide</a>
                                </li>

                            </ul> */}
              <ul className="footer-list">
                <li>
                  <p className="footer-item-title">OTHER LINKS</p>
                </li>

                <li>
                  <a href="/about" className="footer-link">
                    About us
                  </a>
                </li>

                <li>
                  <a href="/refund" className="footer-link">
                    Refund
                  </a>
                </li>

                <li>
                  <a href="/disclaimer" className="footer-link">
                    Disclaimer
                  </a>
                </li>

                <li>
                  <a href="/terms_and_conditions" className="footer-link">
                    Terms and Conditions
                  </a>
                </li>

                <li>
                  <a href="/privacy_policy" className="footer-link">
                    Privacy and Policy
                  </a>
                </li>

                <li>
                  <a href="/developers_contact" className="footer-link">
                    Developer(s) Contact
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            {/* <p className="copyright"> */}
            {/* <span onClick={setOpen1}>Terms and Conditions</span>
              &nbsp;&nbsp;|&nbsp;&nbsp;
              <span
                onClick={() => {
                  alert("Helo");
                }}
              >
                Privacy and Policy
              </span> */}
            {/* <span onClick={setOpen1}>
                Terms and Conditions with Privacy and Policy
              </span> */}
            {/* </p>
            <br /> */}
            <p className="copyright">
              &copy; {new Date().getFullYear()}{" "}
              <a href="/">JITW Systems pvt ltd</a>. All Rights Reserved
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
