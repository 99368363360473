import { ActionTypes } from "../contants/action-types";


const initial = {
    usersData: {},
    userById: {},
}

export const userReducer = (state = initial, { type, payload }) => {

    switch (type) {
        case ActionTypes.USER:
            return { ...state, error: false };
        case ActionTypes.USER_SUCCESS:
            return { ...state, usersData: payload, error: false };
        case ActionTypes.USER_FAILED:
            return { ...state, error: true };

        case ActionTypes.USER_ID:
            return { ...state, error: false };
        case ActionTypes.USER_ID_SUCCESS:
            return { ...state, userById: payload, error: false };
        case ActionTypes.USER_ID_FAILED:
            return { ...state, error: true };
        default:
            return state;
    }
};
