import "./App.css";

import PersistentDrawerLeft from "./components/Drawer";
import HelpDeskPersistentDrawerLeft from "./containers/helpdesk/components/Drawer";
import EngineerPersistentDrawerLeft from "./containers/engineer/components/Drawer";
import CustomerPersistentDrawerLeft from "./containers/customer/components/Drawer";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Protected from "./components/Protected";

//admin
import Login from "./pages/Login";
import Register from "./pages/Register";
import Dashboard from "./pages/Dashboard";
import Complaints from "./pages/Complaints";
import Engineers from "./pages/Engineers";
import AddComplaint from "./pages/AddComplaint";
import Customers from "./pages/Customers";
import HelpDesk from "./pages/HelpDesk";
import Account from "./pages/Account";
import ManageRights from "./pages/ManageRights";

import Home from "./pages/Home";
import CreateUser from "./pages/CreateUser";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from "./pages/ForgotPassword";

//helpdesk
import HelpDeskDashboard from "./containers/helpdesk/pages/Dashboard";
import HelpDeskComplaints from "./containers/helpdesk/pages/Complaints";
import HelpDeskEngineers from "./containers/helpdesk/pages/Engineers";
import HelpDeskAddComplaint from "./containers/helpdesk/pages/AddComplaint";
import HelpDeskCustomers from "./containers/helpdesk/pages/Customers";
import HelpDeskAccount from "./containers/helpdesk/pages/Account";
import HelpDeskCreateUser from "./containers/helpdesk/pages/CreateUser";

//engineer
import EngineerDashboard from "./containers/engineer/pages/Dashboard";
import EngineerComplaints from "./containers/engineer/pages/Complaints";
import EngineerAccount from "./containers/engineer/pages/Account";

//user/customer
import CustomerDashboard from "./containers/customer/pages/Dashboard";
import CustomerComplaints from "./containers/customer/pages/Complaints";
import CustomerAccount from "./containers/customer/pages/Account";
import CustomerAddComplaint from "./containers/customer/pages/AddComplaint";

import AboutUs from "./pages/AboutUs";
import Disclaimer from "./pages/Disclaimer";
import PrivacyAndPolicy from "./pages/PrivacyAndPolicy";
import TermsAndConditions from "./pages/TermsAndConditions";
import Refund from "./pages/Refund";

import Pdf from "./pages/Pdf";
import DevelopersContact from "./pages/DevelopersContact";
import CallRequest from "./components/CallRequest";

function App() {
  //admin
  const myroutes = [
    {
      path: "/Dashboard",
      component: <Dashboard />,
    },
    {
      path: "/Services",
      component: <Complaints />,
    },
    {
      path: "/Engineers",
      component: <Engineers />,
    },
    {
      path: "/HelpDesk",
      component: <HelpDesk />,
    },
    {
      path: "/Customers",
      component: <Customers />,
    },
    {
      path: "/RequestService",
      component: <AddComplaint />,
    },
    {
      path: "/Account",
      component: <Account />,
    },
    {
      path: "/CreateUser",
      component: <CreateUser />,
    },
    {
      path: "/ManageRights",
      component: <ManageRights />,
    },
  ];

  //helpdesk
  const helpdeskroutes = [
    {
      path: "/HelpDeskDashboard",
      component: <HelpDeskDashboard />,
    },
    {
      path: "/HelpDeskComplaints",
      component: <HelpDeskComplaints />,
    },
    {
      path: "/HelpDeskEngineers",
      component: <HelpDeskEngineers />,
    },
    {
      path: "/HelpDeskCustomers",
      component: <HelpDeskCustomers />,
    },
    {
      path: "/HelpDeskRequestService",
      component: <HelpDeskAddComplaint />,
    },
    {
      path: "/HelpDeskAccount",
      component: <HelpDeskAccount />,
    },
    {
      path: "/HelpDeskCreateUser",
      component: <HelpDeskCreateUser />,
    },
  ];

  //engineer
  const engineerroutes = [
    {
      path: "/EngineerDashboard",
      component: <EngineerDashboard />,
    },
    {
      path: "/EngineerServices",
      component: <EngineerComplaints />,
    },
    {
      path: "/EngineerAccount",
      component: <EngineerAccount />,
    },
  ];

  //user/customer
  const customerroutes = [
    {
      path: "/CustomerDashboard",
      component: <CustomerDashboard />,
    },
    {
      path: "/CustomerServices",
      component: <CustomerComplaints />,
    },
    {
      path: "/CustomerAccount",
      component: <CustomerAccount />,
    },
    {
      path: "/CustomerRequestService",
      component: <CustomerAddComplaint />,
    },
  ];

  return (
    <div>
      <BrowserRouter>
        <CallRequest />
        <Routes>
          <Route exact path="/" element={<Home />} />

          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />

          <Route exact path="/forgotPassword" element={<ForgotPassword />} />

          <Route
            exact
            path="/resetPassword/:token/:id"
            element={<ResetPassword />}
          />

          <Route exact path="/about" element={<AboutUs />} />
          <Route exact path="/refund" element={<Refund />} />
          <Route exact path="/disclaimer" element={<Disclaimer />} />
          <Route
            exact
            path="/terms_and_conditions"
            element={<TermsAndConditions />}
          />
          <Route exact path="/privacy_policy" element={<PrivacyAndPolicy />} />
          <Route
            exact
            path="/developers_contact"
            element={<DevelopersContact />}
          />

          <Route exact path="/pdf" element={<Pdf />} />

          {/* admin */}

          {myroutes.map((data, key) => (
            <Route path="/" element={<Protected role={"admin"} />} key={key}>
              <Route
                path={data.path}
                element={
                  <PersistentDrawerLeft>{data.component}</PersistentDrawerLeft>
                }
              />
            </Route>
          ))}

          {/* helpdesk */}

          {helpdeskroutes.map((data, key) => (
            <Route path="/" element={<Protected role={"helpdesk"} />} key={key}>
              <Route
                path={data.path}
                element={
                  <HelpDeskPersistentDrawerLeft>
                    {data.component}
                  </HelpDeskPersistentDrawerLeft>
                }
              />
            </Route>
          ))}

          {/* engineer */}

          {engineerroutes.map((data, key) => (
            <Route path="/" element={<Protected role={"engineer"} />} key={key}>
              <Route
                path={data.path}
                element={
                  <EngineerPersistentDrawerLeft>
                    {data.component}
                  </EngineerPersistentDrawerLeft>
                }
              />
            </Route>
          ))}

          {/* user/customer */}

          {customerroutes.map((data, key) => (
            <Route path="/" element={<Protected role={"user"} />} key={key}>
              <Route
                path={data.path}
                element={
                  <CustomerPersistentDrawerLeft>
                    {data.component}
                  </CustomerPersistentDrawerLeft>
                }
              />
            </Route>
          ))}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
