import { ActionTypes } from "../contants/action-types";


const initial = {
    currentuser: {},
    error: false,
}

export const authReducer = (state = initial, { type, payload }) => {

    switch (type) {
        case ActionTypes.LOGIN:
            return { ...state, error: false };
        case ActionTypes.LOGIN_SUCCESS:
            if (payload.status) {
                const { token, ...currentuser } = payload.data;
                localStorage.setItem('loggedIn', true)
                localStorage.setItem('token', payload.data.token)
                localStorage.setItem('user', JSON.stringify(currentuser))
            }
            return { ...state, currentuser: payload, error: false };
        case ActionTypes.LOGIN_FAILED:
            return { ...state, error: true };
        default:
            return state;
    }
};
