import React, { useEffect, useState } from "react";

import "../styles/home.css";

import one from "../assets/images/1.png";
import two from "../assets/images/2.png";
import s1 from "../assets/images/s1.png";
import { Box, Button, Fade, Link, Modal, Typography } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import StarPurple500Icon from "@mui/icons-material/StarPurple500";

import InfoIcon from "@mui/icons-material/Info";
import Header from "../components/Header";
import Footer from "../components/Footer";

export default function Home() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 300,
    // maxWidth: 'auto',
    bgcolor: "background.paper",
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const icon = {
    color: "#0f98d9",
    marginRight: 2,
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="home_div">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        // BackdropComponent={Backdrop}
        // BackdropProps={{
        //   timeout: 500,
        // }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <Typography
                id="transition-modal-title"
                variant="h6"
                component="h2"
                color={"#0f98d9"}
              >
                IT consulting -
              </Typography>
              <ion-icon
                onClick={handleClose}
                style={{
                  fontSize: "22px",
                  cursor: "pointer",
                }}
                name="close"
              ></ion-icon>
            </div>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <div className="alertDiv">
                <StarPurple500Icon sx={icon} />
                <Typography>
                  Remote support, IT support and guidance on call, expert advice
                  on purchasing new Laptop or Desktop.
                </Typography>
              </div>
              <div className="alertDiv">
                <StarPurple500Icon sx={icon} />
                <Typography>
                  Any kind of spare part and hardware installation like RAM,
                  HDD/SSD,Graphics card etc.
                </Typography>
              </div>
              <div className="alertDiv">
                <StarPurple500Icon sx={icon} />
                <Typography>
                  Desktop and Laptop setup, Printer setup, Wi-fi router and
                  access point setup and configuration.
                </Typography>
              </div>
              <div className="alertDiv">
                <StarPurple500Icon sx={icon} />
                <Typography>
                  Any kind of software installation like MS office, excel,
                  outlook, Photo shop, adobe reader, AutoCAD etc,
                </Typography>
              </div>
              <div className="alertDiv">
                <InfoIcon />{" "}
                <Typography
                  sx={{
                    color: "hsl(245, 17%, 27%)",
                    marginLeft: 2,
                  }}
                >
                  Note:- We only install trial version of software if you
                  already have license software then we install the same.
                </Typography>
              </div>
            </Typography>
          </Box>
        </Fade>
      </Modal>

      <Header />

      <main>
        <article>
          <section className="hero" id="hero">
            <div className="container">
              <div className="hero-content">
                {/* <h1 className="h1 hero-title"><span style={{
                                    color: 'hsl(245, 67%, 59%)',
                                }}>IT WALE</span> The Best Service Providers</h1>

                                <p className="hero-text">
                                    Let's not waste your time join and get the services from IT WALE you will be amazed.
                                </p> */}

                <h1 className="h1 hero-title">
                  <span
                    style={{
                      color: "#0f98d9",
                    }}
                  >
                    IT WALE
                  </span>{" "}
                  Services
                </h1>

                <p className="hero-text">
                  Hassle free repair and maintenance service at your doorstep.
                </p>

                <p className="form-text">
                  {/* <span>🥳</span> Nemo enim ipsam voluptatem quia voluptas sit aspernatur that is fugit. */}
                  {/* <button style={{
                                        position: 'relative',
                                        margin: 0
                                    }} type="submit" className="btn btn-primary">Let's Go</button> */}
                  <Link
                    href="/login"
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    <button
                      style={{
                        position: "relative",
                        margin: 0,
                      }}
                      className="btn btn-primary"
                    >
                      Let's Go
                    </button>
                  </Link>
                </p>

                {/* <form action="" className="hero-form">
                                    <input type="email" name="email" required placeholder="Enter Your Email" className="email-field" />

                                    <button type="submit" className="btn btn-primary">Subscribe</button>
                                </form> */}
              </div>

              <figure className="hero-banner">
                <img src={one} alt="Hero image" />
              </figure>
            </div>
          </section>

          <section className="about" id="about">
            <div className="container">
              <div className="about-content">
                <figure className="features-banner">
                  <img src={s1} alt="illustration art" />
                </figure>
                {/* <div className="about-icon">
                                    <ion-icon name="cube"></ion-icon>
                                </div>

                                <h2 className="h2 about-title">It Wale Services</h2>

                                <p className="about-text">
                                    Hassle free repair and maintenance service at your doorstep.
                                </p>

                                <button className="btn btn-outline" onClick={handleOpen}>Learn More</button> */}
              </div>

              <ul className="about-list">
                <li>
                  <div className="about-card">
                    <div className="card-icon">
                      <ion-icon name="desktop"></ion-icon>
                    </div>

                    <h3 className="h3 card-title">
                      Desktop Repair and Maintenance <span>@599</span>
                    </h3>

                    {/* <p className="card-text">
                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem.
                                        </p> */}
                  </div>
                </li>

                <li>
                  <div className="about-card">
                    <div className="card-icon">
                      <ion-icon name="laptop"></ion-icon>
                    </div>

                    <h3 className="h3 card-title">
                      Laptop Repair and Maintenance <span>@599</span>
                    </h3>

                    {/* <p className="card-text">
                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem.
                                        </p> */}
                  </div>
                </li>

                <li>
                  <div className="about-card">
                    <div className="card-icon">
                      <ion-icon name="logo-apple"></ion-icon>
                    </div>

                    <h3 className="h3 card-title">
                      Macbook Repair and Maintenance <span>@999</span>
                    </h3>

                    {/* <p className="card-text">
                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem.
                                        </p> */}
                  </div>
                </li>

                <li onClick={handleOpen}>
                  <div className="about-card">
                    <div className="card-icon">
                      <ion-icon name="shield-checkmark"></ion-icon>
                    </div>

                    <h3 className="h3 card-title">
                      IT Consulting <span>@599</span>
                    </h3>

                    {/* <p className="card-text">
                                            Sed ut perspiciatis unde omnis iste natus error sit voluptatem.
                                        </p> */}
                  </div>
                </li>
              </ul>
            </div>
          </section>

          <section className="features" id="features">
            <div className="container">
              <h2 className="h2 section-title">How it Works</h2>

              <p className="section-text">Learn about our service work flow.</p>

              <div className="features-wrapper">
                <figure className="features-banner">
                  <img src={two} alt="illustration art" />
                </figure>

                <div className="features-content">
                  <p className="features-content-subtitle">
                    <ion-icon name="sparkles"></ion-icon>

                    {/* <span>CREATIVE FEATURES</span> */}
                  </p>

                  <h3 className="features-content-text">
                    Choose your device & the required service.
                  </h3>

                  <p className="features-content-subtitle">
                    <ion-icon name="sparkles"></ion-icon>

                    {/* <span>CREATIVE FEATURES</span> */}
                  </p>

                  <h3 className="features-content-text">
                    Fill in your details & request a service.
                  </h3>

                  <p className="features-content-subtitle">
                    <ion-icon name="sparkles"></ion-icon>

                    {/* <span>CREATIVE FEATURES</span> */}
                  </p>

                  <h3 className="features-content-text">
                    Our Qualified engineers will visit your home for service.
                  </h3>

                  <p className="features-content-subtitle">
                    <ion-icon name="sparkles"></ion-icon>

                    {/* <span>CREATIVE FEATURES</span> */}
                  </p>

                  <h3 className="features-content-text">
                    Our engineer will first inspect your laptop, diagnose the
                    issue and then recommend the required solution.
                  </h3>

                  {/* <p className="features-content-subtitle">
                    <ion-icon name="sparkles"></ion-icon>
                  </p>

                  <h3 className="features-content-text">
                    The cost of spare parts and part/motherboard repairs if
                    needed, is not covered in the diagnostic service.
                  </h3> */}

                  <p
                    style={{
                      color: "#EDE04D",
                      fontSize: "25px",
                    }}
                  >
                    <ion-icon name="warning"></ion-icon>
                  </p>
                  <br />
                  <p
                    className="features-content-text"
                    style={{
                      // color: "#fff",
                      fontWeight: "700",
                      // background: '#EDE04D',
                      letterSpacing: "1px",
                    }}
                  >
                    <mark
                      style={{
                        background: "#EDE04D",
                        color: "#00000099",
                      }}
                    >
                      The cost of spare parts and part/motherboard repairs if
                      needed, is not covered in the diagnostic service.
                    </mark>
                  </p>

                  {/* <p className="features-content-text">
                                        Temporibus autem quibusdam et aut officiis debitis aut rerum a necessitatibus saepe eveniet ut et
                                        voluptates repudiandae
                                        sint molestiae non recusandae itaque.
                                    </p> */}

                  {/* <ul className="features-list">

                                        <li className="features-list-item">
                                            <ion-icon name="layers-outline"></ion-icon>

                                            <p>Donec pede justo fringilla vel nec.</p>
                                        </li>

                                        <li className="features-list-item">
                                            <ion-icon name="megaphone-outline"></ion-icon>

                                            <p>Cras ultricies mi eu turpis hendrerit fringilla.</p>
                                        </li>

                                    </ul> */}

                  <p
                    style={{
                      color: "#EDE04D",
                      fontSize: "25px",
                    }}
                  >
                    <ion-icon name="warning"></ion-icon>
                  </p>
                  <br />
                  <p
                    className="features-content-text"
                    style={{
                      // color: "#fff",
                      fontWeight: "700",
                      // background: '#EDE04D',
                      letterSpacing: "1px",
                    }}
                  >
                    <mark
                      style={{
                        background: "#EDE04D",
                        color: "#00000099",
                      }}
                    >
                      {/* What are we covered in Diagnostic Service :- System slow,
                      OS installation, system cleaning and any other issue which
                      not required extra repair or part replacement. */}
                      What we cover in Diagnostic Service :- System slow, OS
                      installation, System cleaning and other issue which not
                      required extra repair or part replacement.
                    </mark>
                  </p>

                  <div className="btn-group">
                    {/* <button className="btn btn-primary">Read More</button> */}
                    <Link
                      style={{
                        textDecoration: "none",
                      }}
                      href={"/register"}
                    >
                      <button className="btn btn-secondary">Check</button>
                    </Link>
                  </div>
                </div>
              </div>

              {/* <div className="features-wrapper">

                                <figure className="features-banner">
                                    <img src={features2} alt="illustration art" />
                                </figure>

                                <div className="features-content">

                                    <p className="features-content-subtitle">
                                        <ion-icon name="sparkles"></ion-icon>

                                        <span>CREATIVE FEATURES</span>
                                    </p>

                                    <h3 className="features-content-title">
                                        We do the work you <strong>stay focused</strong> on <strong>your customers.</strong>
                                    </h3>

                                    <p className="features-content-text">
                                        Temporibus autem quibusdam et aut officiis debitis aut rerum a necessitatibus saepe eveniet ut et
                                        voluptates repudiandae
                                        sint molestiae non recusandae itaque.
                                    </p>

                                    <ul className="features-list">

                                        <li className="features-list-item">
                                            <ion-icon name="rocket-outline"></ion-icon>

                                            <p>Donec pede justo fringilla vel nec.</p>
                                        </li>

                                        <li className="features-list-item">
                                            <ion-icon name="wifi-outline"></ion-icon>

                                            <p>Cras ultricies mi eu turpis hendrerit fringilla.</p>
                                        </li>

                                    </ul>

                                    <div className="btn-group">
                                        <button className="btn btn-primary">Read More</button>

                                        <button className="btn btn-secondary">Buy Now</button>
                                    </div>

                                </div>

                            </div> */}
            </div>
          </section>

          {/* <section className="blog" id="blog">
                        <div className="container">

                            <h2 className="h2 section-title">Latest News</h2>

                            <p className="section-text">
                                Et harum quidem rerum facilis est et expedita distinctio nam libero tempore cum soluta nobis eligendi
                                cumque.
                            </p>

                            <ul className="blog-list">

                                <li>
                                    <div className="blog-card">

                                        <figure className="blog-banner">
                                            <img src={blog_banner} alt="Best Traveling Place" />
                                        </figure>

                                        <div className="blog-meta">

                                            <span>
                                                <ion-icon name="calendar-outline"></ion-icon>

                                                <time datetime="2021-04-10">april 10 2021</time>
                                            </span>

                                            <span>
                                                <ion-icon name="person-outline"></ion-icon>

                                                <p>admin</p>
                                            </span>

                                        </div>

                                        <h3 className="blog-title">Best Traveling Place</h3>

                                        <p className="blog-text">
                                            Integer ante arcu accumsan a consectetuer eget posuere mauris praesent adipiscing phasellus
                                            ullamcorper ipsum rutrum
                                            punc.
                                        </p>

                                        <a href="#" className="blog-link-btn">
                                            <span>Learn More</span>

                                            <ion-icon name="chevron-forward-outline"></ion-icon>
                                        </a>

                                    </div>
                                </li>

                                <li>
                                    <div className="blog-card">

                                        <figure className="blog-banner">
                                            <img src={blog_banner2} alt="Private Meeting Room" />
                                        </figure>

                                        <div className="blog-meta">

                                            <span>
                                                <ion-icon name="calendar-outline"></ion-icon>

                                                <time datetime="2021-04-10">april 10 2021</time>
                                            </span>

                                            <span>
                                                <ion-icon name="person-outline"></ion-icon>

                                                <p>admin</p>
                                            </span>

                                        </div>

                                        <h3 className="blog-title">Private Meeting Room</h3>

                                        <p className="blog-text">
                                            Integer ante arcu accumsan a consectetuer eget posuere mauris praesent adipiscing phasellus
                                            ullamcorper ipsum rutrum
                                            punc.
                                        </p>

                                        <a href="#" className="blog-link-btn">
                                            <span>Learn More</span>

                                            <ion-icon name="chevron-forward-outline"></ion-icon>
                                        </a>

                                    </div>
                                </li>

                                <li>
                                    <div className="blog-card">

                                        <figure className="blog-banner">
                                            <img src={blog_banner3} alt="The Best Business Ideas" />
                                        </figure>

                                        <div className="blog-meta">

                                            <span>
                                                <ion-icon name="calendar-outline"></ion-icon>

                                                <time datetime="2021-04-10">april 10 2021</time>
                                            </span>

                                            <span>
                                                <ion-icon name="person-outline"></ion-icon>

                                                <p>admin</p>
                                            </span>

                                        </div>

                                        <h3 className="blog-title">The Best Business Ideas</h3>

                                        <p className="blog-text">
                                            Integer ante arcu accumsan a consectetuer eget posuere mauris praesent adipiscing phasellus
                                            ullamcorper ipsum rutrum
                                            punc.
                                        </p>

                                        <a href="#" className="blog-link-btn">
                                            <span>Learn More</span>

                                            <ion-icon name="chevron-forward-outline"></ion-icon>
                                        </a>

                                    </div>
                                </li>

                            </ul>

                        </div>
                    </section> */}

          <section className="benefits" id="benefits">
            <div className="container">
              <h2 className="h2 section-title">Service Benefits We Offer</h2>

              <p className="section-text">.</p>
            </div>

            <div className="cen">
              <div className="service">
                <div className="card-icon">
                  <ion-icon name="person"></ion-icon>
                </div>
                <h2>Qualified Engineers</h2>
                <p>
                  We have our own trained engineers who take less time to
                  diagnose the problem.
                </p>
              </div>

              <div className="service">
                <div className="card-icon">
                  <ion-icon name="medal"></ion-icon>
                </div>
                <h2>High-Quality Services</h2>
                <p>30-days warranty on service & 90-days on spare parts.</p>
              </div>

              <div className="service">
                <div className="card-icon">
                  <ion-icon name="ribbon"></ion-icon>
                </div>
                <h2>Service Expertise</h2>
                <p>Trusted by 1 Lakh+ Customers & 100+ Corporates.</p>
              </div>

              {/* <div className="service">
                                <div className='card-icon'>
                                    <ion-icon name="clipboard"></ion-icon>
                                </div>
                                <h2>Select Complaint Register</h2>
                                <p>Choose your device & the required complaint.</p>
                            </div>

                            <div className="service">
                                <div className='card-icon'>
                                    <ion-icon name="cog"></ion-icon>
                                </div>
                                <h2>Raise Complaint</h2>
                                <p>Fill in your details & raise the complaint.</p>
                            </div>

                            <div className="service">
                                <div className='card-icon'>
                                    <ion-icon name="eye"></ion-icon>
                                </div>
                                <h2>Service Visit</h2>
                                <p>Our Qualified engineers will visit your home for service.</p>
                            </div> */}
            </div>

            {/* <div className='extratext'>
                            <p>*Our engineer will first inspect your laptop, diagnose the issue and then recommend the required solution.</p>
                            <p>*The cost of spare parts, if needed, is not covered in the laptop repair service.</p>
                        </div> */}
          </section>

          <section className="contact" id="contact">
            <div className="container">
              <h2 className="h2 section-title">Contact Us</h2>

              <p className="section-text">.</p>

              <div className="contact-wrapper">
                {/* <form action="" className="contact-form">

                                    <div className="wrapper-flex">

                                        <div className="input-wrapper">
                                            <label for="name">Name*</label>

                                            <input type="text" name="name" id="name" required placeholder="Enter Your Name" className="input-field" />
                                        </div>

                                        <div className="input-wrapper">
                                            <label for="emai">Email*</label>

                                            <input type="text" name="email" id="email" required placeholder="Enter Your Email"
                                                className="input-field" />
                                        </div>

                                    </div>

                                    <label for="message">Message*</label>

                                    <textarea name="message" id="message" required placeholder="Type Your Message"
                                        className="input-field"></textarea>

                                    <button type="submit" className="btn btn-primary">
                                        <span>Send Message</span>

                                        <ion-icon name="paper-plane-outline"></ion-icon>
                                    </button>

                                </form> */}

                <ul className="contact-list">
                  <li>
                    <a
                      href="mailto:support@itwaleservices.com"
                      className="contact-link"
                    >
                      <ion-icon name="mail-outline"></ion-icon>

                      <span>: support@itwaleservices.com</span>
                    </a>
                  </li>

                  {/* <li>
                    <a href="#" className="contact-link">
                      <ion-icon name="globe-outline"></ion-icon>

                      <span>: www.website.com</span>
                    </a>
                  </li> */}

                  <li>
                    <a href="tel:+918595950289" className="contact-link">
                      <ion-icon name="call-outline"></ion-icon>

                      <span>: (+91) 8595950289</span>
                    </a>
                  </li>

                  {/* <li>
                    <div className="contact-link">
                      <ion-icon name="time-outline"></ion-icon>

                      <span>: 9:00 AM - 6:00 PM</span>
                    </div>
                  </li> */}

                  <li>
                    <a href="#" className="contact-link">
                      <ion-icon name="location-outline"></ion-icon>

                      <address>
                        : B-401, First Floor, Neelam Road, Nehru Ground,
                        Faridabad, Faridabad, Haryana, 121001
                      </address>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </section>
        </article>
      </main>

      <Footer />
    </div>
  );
}
