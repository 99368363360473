import React from 'react'
import UsersTable from '../components/UsersTable'

function HelpDesk() {
  return (
    <div>
      <UsersTable title={'HelpDesk'} role={'helpdesk'} />
    </div>
  )
}

export default HelpDesk