import React, { useRef, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { Alert, Snackbar, Stack } from "@mui/material";
import { SendCallRequestEmail } from "./SendEmail";

function CallRequest() {
  const [open, setOpen] = useState(false);
  const [form, setForm] = useState({});

  const myForm = useRef();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setForm({});
  };

  const [open1, setOpen1] = React.useState(false);

  const handleClickSnack = () => {
    setOpen1(true);
  };

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen1(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (!form.name || !form.email || !form.mobile) {
      return null;
    }
    // axios
    //   .post("/user/requestCall", form)
    //   .then((res) => {
    //     if (res.data.status) {
    //       handleClose();
    //       handleClickSnack();
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err.message);
    //   });

    SendCallRequestEmail(myForm);
    handleClose();
    handleClickSnack();

    // emailjs
    //   .sendForm(
    //     "service_tfpt0up",
    //     "template_zzkrjb6",
    //     myForm.current,
    //     "xw42k-Kx-_7osHt5g"
    //   )
    //   .then(
    //     (result) => {
    //       console.log(result.text);
    //       handleClose();
    //       handleClickSnack();
    //     },
    //     (error) => {
    //       console.log(error.text);
    //     }
    //   );
  };

  return (
    <>
      <div
        style={{
          position: "fixed",
          // width: "100px",
          background: "#0f98d9",
          zIndex: 50,
          padding: "10px",
          right: 0,
          bottom: 80,
          cursor: "pointer",
        }}
      >
        <button
          style={{
            color: "white",
            fontWeight: "700",
          }}
          onClick={handleClickOpen}
        >
          <i>Requset a call</i>
        </button>
      </div>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open1}
          autoHideDuration={4000}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity="success"
            sx={{ width: "100%" }}
          >
            Call Request placed....
          </Alert>
        </Snackbar>
      </Stack>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Requset a call</DialogTitle>
        <form ref={myForm} onSubmit={onSubmit}>
          <DialogContent>
            {/* <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText> */}
            <TextField
              // autoFocus
              margin="dense"
              id="name"
              name="name"
              label="Name"
              type="text"
              fullWidth
              variant="standard"
              onChange={(e) => {
                setForm({ ...form, name: e.target.value });
              }}
              required
            />
            <TextField
              // autoFocus
              margin="dense"
              id="mobile"
              name="mobile"
              label="Mobile"
              type="tel"
              fullWidth
              variant="standard"
              onChange={(e) => {
                setForm({ ...form, mobile: e.target.value });
              }}
              required
            />
            <TextField
              // autoFocus
              margin="dense"
              id="email"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="standard"
              onChange={(e) => {
                setForm({ ...form, email: e.target.value });
              }}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Requset</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

export default CallRequest;
