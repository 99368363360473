import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { CircularProgress } from "@mui/material";
import axios from "axios";

import { useDispatch } from "react-redux";

import { SendResetEmail } from "../components/SendEmail";

const theme = createTheme();

export default function ForgotPassword() {
  const [errors, setErrors] = React.useState({});

  const [loading, setLoading] = React.useState(false);

  const [status, setStatus] = React.useState(false);

  const myForm = React.useRef();

  const [link, setLink] = React.useState('')

  const onChange = ({ name, value }) => {
    if (value !== "") {
      if (name === "email") {
        var pattern = /^[a-zA-Z]([a-zA-Z0-9_]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,})$/;

        if (pattern.test(value)) {
          setErrors((prev) => {
            return { ...prev, [name]: null };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, [name]: "Please enter valid email address" };
          });
        }
      } else {
        setErrors((prev) => {
          return { ...prev, [name]: null };
        });
      }
    } else {
      setErrors((prev) => {
        return { ...prev, [name]: "This field is required...." };
      });
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    if (!data.get("email").length) {
      setErrors((prev) => {
        return { ...prev, email: "Please enter valid email address...." };
      });
    }

    if (data.get("email").length) {
      setLoading(true);
      axios
        .post("/user/resetLink", {
          email: data.get("email"),
        })
        .then((res) => {
          if (res.data.status) {
            // setLink(res.data.data.link)
            SendResetEmail(res.data.data.email, res.data.data.link)
            setStatus(true);
          }
        })
        .catch((err) => {
          setLoading(false);
          setErrors((prev) => {
            return { ...prev, email: "Email does not existed...." };
          });
        });
    }
  };

  var isLoggedIn = localStorage.getItem("loggedIn");

  React.useEffect(() => {
    if (isLoggedIn) {
      isLoggedIn = JSON.parse(localStorage.getItem("loggedIn"));
      const user = JSON.parse(localStorage.getItem("user"));
      if (isLoggedIn) {
        // window.location.reload()
        // navigate("/Dashboard")
        if (user.role === "admin") {
          window.location.replace("/dashboard");
        }
        if (user.role === "helpdesk") {
          window.location.replace("/helpdeskdashboard");
        }
        if (user.role === "engineer") {
          window.location.replace("/engineerdashboard");
        }
        if (user.role === "user") {
          window.location.replace("/customerdashboard");
        }
      }
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography component="h1" variant="h5">
            Please enter your registered Email
          </Typography>
          <Box
            component="form"
            // ref={myForm}
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              disabled={status ? true : false}
              onChange={(e) => {
                onChange({ name: "email", value: e.target.value });
              }}
            />
            {errors.email && (
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity="error">{errors.email}!</Alert>
              </Stack>
            )}
            {/* <input type="text" name="link" id="link" value={link} hidden /> */}
            {!status && (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={loading ? true : false}
              >
                {loading ? (
                  <CircularProgress
                    sx={{
                      color: "white",
                    }}
                    size={25}
                  />
                ) : null}
                Submit
              </Button>
            )}
            {status && (
              <>
                <Typography
                  sx={{
                    textAlign: "center",
                    color: "green",
                  }}
                >
                  Reset link has been sent....
                </Typography>
                <Button
                  onClick={() => {
                    window.location.replace("/login");
                  }}
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Login
                </Button>
              </>
            )}
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
      </Container>
    </ThemeProvider>
  );
}
