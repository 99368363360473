import axios from "axios";

import { ActionTypes } from "../contants/action-types";

export const getUsers = (role) => async (dispatch) => {

    try {

        dispatch({
            type: ActionTypes.USER
        })

        const response = await axios.post(`/user/getallusers`, {
            role: role
        });

        dispatch({
            type: ActionTypes.USER_SUCCESS,
            payload: response.data,
        })

    }
    catch (error) {
        dispatch({
            type: ActionTypes.USER_FAILED,
            error: true
        })
    }

};

export const getUserById = (id) => async (dispatch) => {

    try {

        dispatch({
            type: ActionTypes.USER_ID
        })

        const response = await axios.post(`/user/getallusers`, {
            id: id
        });

        dispatch({
            type: ActionTypes.USER_ID_SUCCESS,
            payload: response.data,
        })

    }
    catch (error) {
        dispatch({
            type: ActionTypes.USER_ID_FAILED,
            error: true
        })
    }

};
