import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import LabTabs from "../components/LabTabs";
import { getComplaints } from "../redux/actions/complaintActions";

function Complaints() {
  const data = [
    {
      label: "Total",
      title: "Total Services",
      status: null,
    },
    {
      label: "Pending",
      title: "Pending Services",
      status: "pending",
    },
    {
      label: "Closed",
      title: "Closed Services",
      status: "closed",
    },
    {
      label: "Assigned",
      title: "Assigned Services",
      status: "assigned",
    },
    {
      label: "Un-Assigned",
      title: "Un-Assigned Services",
      status: "un-assigned",
    },
    {
      label: "Re-Opened",
      title: "Re-Opened Services",
      status: "re-opened",
    },
    // {
    //   label: 'Remarks',
    //   title: 'Complaint Remarks',
    //   status: 'remarked',
    // },
  ];

  var pending = 0,
    closed = 0,
    assigned = 0,
    un_assigned = 0,
    re_opened = 0,
    remarked = 0;

  var counts = [];

  const dispatch = useDispatch();

  const complaints = useSelector((state) => state.complaints.complaints);

  useEffect(() => {
    dispatch(getComplaints());
  }, []);

  if (complaints && complaints.status) {
    const p = complaints.data.filter((com) => com.status === "pending");
    const c = complaints.data.filter((com) => com.status === "closed");
    const a = complaints.data.filter((com) => com.status === "assigned");
    const u = complaints.data.filter((com) => com.status === "un-assigned");
    const r = complaints.data.filter((com) => com.status === "re-opened");
    const rm = complaints.data.filter((com) => com.status === "remarked");
    pending = p.length;
    closed = c.length;
    assigned = a.length;
    un_assigned = u.length;
    re_opened = r.length;
    remarked = rm.length;
    counts.push(pending + closed + assigned + un_assigned + re_opened);
    counts.push(pending);
    counts.push(closed);
    counts.push(assigned);
    counts.push(un_assigned);
    counts.push(re_opened);
  }

  return (
    <div>
      <LabTabs data={data} counts={counts} />
    </div>
  );
}

export default Complaints;
