import React, { useState } from "react";

import logo from "../assets/images/logo.png";

function Header() {
  const [isActive, setIsActive] = useState(false);

  return (
    <div>
      <header className={`header ${isActive ? "active" : ""}`} data-header>
        <div className="container">
          <a
            href="#"
            className="logo"
            style={{
              width: "50px",
              height: "50px",
            }}
          >
            <img
              style={{
                height: "100%",
                width: "100%",
              }}
              src={logo}
              alt="Landio logo"
            />
          </a>

          <button
            className="menu-toggle-btn"
            data-nav-toggle-btn
            onClick={() => setIsActive(!isActive)}
          >
            <ion-icon name="menu-outline"></ion-icon>
          </button>

          <nav className="navbar">
            <ul className="navbar-list">
              <li>
                <a href="/#hero" className="navbar-link">
                  Home
                </a>
              </li>

              <li>
                <a href="/#about" className="navbar-link">
                  Services
                </a>
              </li>

              <li>
                <a href="/#features" className="navbar-link">
                  How it works
                </a>
              </li>

              <li>
                <a href="/#benefits" className="navbar-link">
                  Benefits
                </a>
              </li>

              <li>
                <a href="/#contact" className="navbar-link">
                  Contact Us
                </a>
              </li>
            </ul>

            <div className="header-actions">
              <a href="http://103.158.65.119/#/auth/login" target="_blank" className="header-action-link">
                Log in
              </a>

              <a href="/register" className="header-action-link">
                Register
              </a>
            </div>
          </nav>
        </div>
      </header>
    </div>
  );
}

export default Header;
