import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import PhotoCamera from "@mui/icons-material/PhotoCamera";

import axios from "axios";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { storage } from "../firebase";
import { v4 } from "uuid";
import {
  Alert,
  Badge,
  CircularProgress,
  IconButton,
  Snackbar,
  Stack,
  Toolbar,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function SignUp() {
  const [imageUrl, setImageUrl] = React.useState("");

  const [form, setForm] = React.useState({ role: "user" });

  const [errors, setErrors] = React.useState({});

  const [error, setError] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const [show, setShow] = React.useState(false);

  const [otp, setOTP] = React.useState("");

  const [showOTP, setShowOTP] = React.useState(false);

  const [verify, setVerify] = React.useState(false);

  const auth = getAuth();

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleClickShowPassword = () => {
    setShow(!show);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onImageUpload = async (e) => {
    const file = e.target.files[0];
    await uploadFile(file);
  };

  const uploadFile = (file) => {
    const imageUpload = file;
    const imageRef = ref(storage, `services/${imageUpload.name + v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrl(url);
      });
    });
  };

  const onChange = ({ name, value }) => {
    if (value !== "") {
      if (name === "email") {
        var pattern = /^[a-zA-Z]([a-zA-Z0-9_]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,})$/;

        if (pattern.test(value)) {
          setErrors((prev) => {
            return { ...prev, [name]: null };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, [name]: "Please enter valid email address" };
          });
        }
      }
      if (name === "mobile") {
        var pattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;

        // const num = Number(value)

        if (pattern.test(value)) {
          setErrors((prev) => {
            return { ...prev, [name]: null };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, [name]: "Please enter valid mobile number" };
          });
        }
      } else {
        setErrors((prev) => {
          return { ...prev, [name]: null };
        });
      }
    } else {
      setErrors((prev) => {
        return { ...prev, [name]: "This field is required...." };
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (imageUrl) setForm({ ...form, image: imageUrl });

    if (!form.name || !form.name.trim().length) {
      return setErrors((prev) => {
        return { ...prev, name: "Please enter valid name...." };
      });
    }
    if (!form.email || !form.email.trim().length) {
      return setErrors((prev) => {
        return { ...prev, email: "Please enter valid email...." };
      });
    }
    if (!form.mobile || !form.mobile.trim().length) {
      return setErrors((prev) => {
        return { ...prev, mobile: "Please enter valid mobile number...." };
      });
    }
    if (!form.password || !form.password.trim().length) {
      return setErrors((prev) => {
        return { ...prev, password: "Please enter valid password...." };
      });
    }
    if (!form.current_address || !form.current_address.trim().length) {
      return setErrors((prev) => {
        return {
          ...prev,
          current_address: "Please enter valid current address....",
        };
      });
    }
    if (!form.permanent_address || !form.permanent_address.trim().length) {
      return setErrors((prev) => {
        return {
          ...prev,
          permanent_address: "Please enter valid permanent address....",
        };
      });
    }

    if (!form.city || !form.city.trim().length) {
      return setErrors((prev) => {
        return {
          ...prev,
          city: "Please enter valid city....",
        };
      });
    }

    if (!form.state || !form.state.trim().length) {
      return setErrors((prev) => {
        return {
          ...prev,
          state: "Please enter valid state....",
        };
      });
    }

    if(!verify){
      return setOpen(true)
    }

    setLoading(true);
    axios
      .post("/user/register", {
        name: form.name,
        image: imageUrl,
        email: form.email,
        password: form.password,
        mobile: form.mobile,
        role: form.role,
        current_address: form.current_address,
        permanent_address: form.permanent_address,
        city: form.city,
        state: form.state,
      })
      .then((res) => {
        if (res.data.status) {
          console.log("success");
          navigate("/login");
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setError("Email or Mobile is already existed....");
        setLoading(false);
      });
  };

  var isLoggedIn = localStorage.getItem("loggedIn");

  const navigate = useNavigate();

  const sendOTP = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          onRegister();
        },
        // default: "IN",
      },
      auth
    );
  };

  const verifyOTP = () => {
    window.confirmationResult
      .confirm(otp)
      .then((res) => {
        if (res && res.user) {
          setVerify(true);
        }
      })
      .catch((error) => {});
  };

  const onRegister = () => {
    sendOTP();

    const phoneNumber = "+91" + form.mobile;
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
        setShowOTP(true);
      })
      .catch((error) => {
        console.log("OTP can't send");
      });
  };

  React.useEffect(() => {
    if (isLoggedIn) {
      isLoggedIn = JSON.parse(localStorage.getItem("loggedIn"));
      const u = localStorage.getItem("user");
      const user = JSON.parse(localStorage.getItem("user"));
      if (isLoggedIn && u) {
        // window.location.reload()
        // navigate("/Dashboard")
        if (user.role === "admin") {
          window.location.replace("/dashboard");
        }
        if (user.role === "helpdesk") {
          window.location.replace("/helpdeskdashboard");
        }
        if (user.role === "engineer") {
          window.location.replace("/engineerdashboard");
        }
        if (user.role === "user") {
          window.location.replace("/customerdashboard");
        }
      }
    }
  }, [isLoggedIn]);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div id="recaptcha-container"></div>
        {/* <button onClick={() => onRegister()}>send</button> */}
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            autoHideDuration={4000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              sx={{ width: "100%" }}
            >
              Please verify mobile number....
            </Alert>
          </Snackbar>
        </Stack>
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Register
          </Typography>
          <Badge
            onChange={onImageUpload}
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input hidden accept="image/*" type="file" />
                <PhotoCamera />
              </IconButton>
            }
            style={{
              color: "gray",
              cursor: "pointer",
              margin: "20px 0",
            }}
          >
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="label"
            >
              <input hidden accept="image/*" type="file" />
              <Avatar
                alt="Travis Howard"
                src={imageUrl}
                sx={{ width: 70, height: 70 }}
              />
            </IconButton>
          </Badge>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            {error && (
              <Stack sx={{ width: "100%", mb: 2 }} spacing={2}>
                <Alert severity="error">{error}!</Alert>
              </Stack>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="given-name"
                  name="name"
                  required
                  fullWidth
                  id="Name"
                  label="Name"
                  onChange={(e) => {
                    setForm({ ...form, name: e.target.value });
                    onChange({ name: "name", value: e.target.value });
                  }}
                />
                {errors.name && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.name}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="mobile"
                  label="Mobile"
                  name="mobile"
                  autoComplete="family-name"
                  onChange={(e) => {
                    setForm({ ...form, mobile: e.target.value });
                    onChange({ name: "mobile", value: e.target.value });
                  }}
                  InputProps={{
                    endAdornment: (
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          onRegister();
                        }}
                      >
                        SEND
                      </button>
                    ),
                  }}
                />
                {errors.mobile && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.mobile}!</Alert>
                  </Stack>
                )}
              </Grid>
              {showOTP && (
                <Grid item xs={12}>
                  <TextField
                    disabled={verify ? true : false}
                    required
                    fullWidth
                    id="otp"
                    label="OTP"
                    name="otp"
                    autoComplete="family-name"
                    onChange={(e) => {
                      setOTP(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <button
                          style={{
                            color: verify ? "green" : "black",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            verifyOTP();
                          }}
                          disabled={verify ? true : false}
                        >
                          {verify ? "VERIFIED" : "VERIFY"}
                        </button>
                      ),
                    }}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => {
                    setForm({ ...form, email: e.target.value });
                    onChange({ name: "email", value: e.target.value });
                  }}
                />
                {errors.email && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.email}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type={show ? "text" : "password"}
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => {
                    setForm({ ...form, password: e.target.value });
                    onChange({ name: "password", value: e.target.value });
                  }}
                  InputProps={{
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {show ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    ),
                  }}
                />
                {errors.password && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.password}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="city"
                  required
                  fullWidth
                  id="city"
                  label="City"
                  onChange={(e) => {
                    setForm({ ...form, city: e.target.value });
                    onChange({ name: "city", value: e.target.value });
                  }}
                />
                {errors.city && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.city}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="state"
                  label="State"
                  name="state"
                  autoComplete="family-name"
                  onChange={(e) => {
                    setForm({ ...form, state: e.target.value });
                    onChange({ name: "state", value: e.target.value });
                  }}
                />
                {errors.state && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.state}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="current_address"
                  label="Current Address"
                  name="current_address"
                  autoComplete="current_address"
                  multiline
                  maxRows={4}
                  onChange={(e) => {
                    setForm({ ...form, current_address: e.target.value });
                    onChange({
                      name: "current_address",
                      value: e.target.value,
                    });
                  }}
                />
                {errors.current_address && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.current_address}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="permanent_address"
                  label="Permanent Address"
                  name="permanent_address"
                  autoComplete="permanent_address"
                  multiline
                  maxRows={4}
                  onChange={(e) => {
                    setForm({ ...form, permanent_address: e.target.value });
                    onChange({
                      name: "permanent_address",
                      value: e.target.value,
                    });
                  }}
                />
                {errors.permanent_address && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.permanent_address}!</Alert>
                  </Stack>
                )}
              </Grid>
              {/* <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                                    label="I want to receive inspiration, marketing promotions and updates via email."
                                />
                            </Grid> */}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading ? true : false}
            >
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                  }}
                  size={25}
                />
              ) : null}
              Submit
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">
                  Already have an account? Login
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Toolbar />
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </Container>
    </ThemeProvider>
  );
}
