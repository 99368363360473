import * as React from 'react';
import { FC, createElement } from 'react';
import { Card, Box, Typography, Divider } from '@mui/material';
import { Link, To } from 'react-router-dom';
import { ReactNode } from 'react';

import cartouche from './cartouche.png';
import cartoucheDark from './cartoucheDark.png';
import DollarIcon from '@mui/icons-material/AttachMoney';

const CardWithIcon = ({ title, value, index, color, background, icon }) => {

    return (
        // @ts-ignore
        <Card
            sx={{
                // minHeight: 52,
                // display: 'flex',
                // flexDirection: 'column',
                // flex: '1',
                '& a': {
                    textDecoration: 'none',
                    color: 'inherit',
                },
                minWidth: 300,
                maxWidth: 'auto',
                mt: 5,
            }}
        >
            <Link to={'/EngineerServices'} state={{ index: index }}>
                <Box
                    sx={{
                        overflow: 'inherit',
                        padding: '16px',
                        // background: theme =>
                        //     `url(${theme.palette.mode === 'dark'
                        //         ? cartoucheDark
                        //         : cartouche
                        //     }) no-repeat`,
                        // background: 'red',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        '& .icon': {
                            color: theme =>
                                theme.palette.mode === 'dark'
                                    ? 'inherit'
                                    // : '#dc2440',
                                    : color,
                        },
                    }}
                >
                    <div style={{
                        position: 'absolute',
                        minWidth: 85,
                        maxWidth: 'auto',
                        minHeight: 87,
                        maxHeight: 'auto',
                        background: background,
                        marginLeft: '-16px',
                        borderTopLeftRadius: '5px',
                        borderBottomRightRadius: '75px'
                    }}></div>
                    <Box width="3em" className="icon" sx={{
                        zIndex: 1,
                    }}>
                        {createElement(icon, { fontSize: 'medium' })}
                    </Box>
                    <Box textAlign="right">
                        <Typography color="textSecondary">{title}</Typography>
                        <Typography variant="h5" component="h2">
                            {value}
                        </Typography>
                    </Box>
                </Box>
            </Link>
        </Card>
    );
};

export default CardWithIcon;
