import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

function Refund() {
  return (
    <div className="home_div">
      <Header />

      <div className="alertDiv1">
        <h2>Refund</h2>
        <p>
          <b>Cancellations</b>
        </p>
        <p>
          If you want to cancel the Service request for some reason, you can
          request a cancellation by contacting us with the details of your
          Requested services. Cancellation request will only be processed if the
          order has not been not given from our side. Once the Service is given,
          it cannot be cancelled. The Services are usually given within 24 hours
          of successful payment. Please note that a cancellation request does
          not guarantee the cancellation of your Request. For security reasons,
          we require you to contact us through the mobile number or email
          address used while ordering. IT Wale services reserves the right to
          reject a request for cancellation.
        </p>
        <p>
          <b>Refunds</b>
        </p>
        <p>
          In case of cancellations or refunds, we will send you an email to
          notify you that we have not taken you service. We will also notify you
          of the approval or rejection of your refund. If you are approved, then
          your refund will be processed, and a credit will automatically be
          applied to your credit card or original method of payment, within a
          certain amount of days.
        </p>
        <p>
          <b>QUESTIONS</b>
        </p>
        <p>
          If you have any questions concerning our refund policy, please contact
          us at: 8595950289 support@itwaleservices.com
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default Refund;
