import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { complaintsReducer } from "./complaintsReducer copy";
import { userReducer } from "./userReducer";
import {rightsReducer} from './rightsReducer'

const reducers = combineReducers({
  currentuser: authReducer,
  users: userReducer,
  complaints: complaintsReducer,
  rights: rightsReducer,
});

export default reducers;
