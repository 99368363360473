import React from 'react'
import UsersTable from '../components/UsersTable'

function Customers() {
  return (
    <div>
      <UsersTable title={'Customers'} role={'user'} />
    </div>
  )
}

export default Customers