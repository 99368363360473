import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Alert, Autocomplete, CircularProgress, Stack } from "@mui/material";

import axios from "axios";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { storage } from "../../../firebase";
import { v4 } from "uuid";

const theme = createTheme();

export default function CreateUser() {
  const usersData = [
    { label: "Admin", value: "admin" },
    { label: "Engineer", value: "engineer" },
    { label: "HelpDesk", value: "helpdesk" },
    { label: "User/Customer", value: "user" },
  ];

  const [imageUrl, setImageUrl] = React.useState("");

  const [form, setForm] = React.useState({ password: "123456" });

  const [errors, setErrors] = React.useState({});

  const [error, setError] = React.useState("");

  const [loading, setLoading] = React.useState(false);

  const onImageUpload = async (e) => {
    const file = e.target.files[0];
    await uploadFile(file);
  };

  const uploadFile = (file) => {
    const imageUpload = file;
    const imageRef = ref(storage, `services/${imageUpload.name + v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {
        setImageUrl(url);
      });
    });
  };

  const onChange = ({ name, value }) => {
    if (value !== "") {
      if (name === "email") {
        var pattern = /^[a-zA-Z]([a-zA-Z0-9_]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,})$/;

        if (pattern.test(value)) {
          setErrors((prev) => {
            return { ...prev, [name]: null };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, [name]: "Please enter valid email address" };
          });
        }
      }
      if (name === "mobile") {
        var pattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;

        // const num = Number(value)

        if (pattern.test(value)) {
          setErrors((prev) => {
            return { ...prev, [name]: null };
          });
        } else {
          setErrors((prev) => {
            return { ...prev, [name]: "Please enter valid mobile number" };
          });
        }
      } else {
        setErrors((prev) => {
          return { ...prev, [name]: null };
        });
      }
    } else {
      setErrors((prev) => {
        return { ...prev, [name]: "This field is required...." };
      });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!form.name || !form.name.trim().length) {
      return setErrors((prev) => {
        return { ...prev, name: "Please enter valid name...." };
      });
    }
    if (!form.email || !form.email.trim().length) {
      return setErrors((prev) => {
        return { ...prev, email: "Please enter valid email...." };
      });
    }
    if (!form.mobile || !form.mobile.trim().length) {
      return setErrors((prev) => {
        return { ...prev, mobile: "Please enter valid mobile number...." };
      });
    }
    if (!form.current_address || !form.current_address.trim().length) {
      return setErrors((prev) => {
        return {
          ...prev,
          current_address: "Please enter valid current address....",
        };
      });
    }
    if (!form.permanent_address || !form.permanent_address.trim().length) {
      return setErrors((prev) => {
        return {
          ...prev,
          permanent_address: "Please enter valid permanent address....",
        };
      });
    }
    if (!form.role || !form.role.trim().length) {
      return setErrors((prev) => {
        return { ...prev, role: "Please select role...." };
      });
    }

    setLoading(true);
    axios
      .post("/user/register", form)
      .then((res) => {
        if (res.data.status) {
          console.log("success");
          window.location.reload();
        }
        setLoading(false);
      })
      .catch((err) => {
        setError("Email or Mobile is already existed....");
        setLoading(false);
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <Typography component="h1" variant="h5">
        Create an User
      </Typography>
      <hr
        style={{
          // margin: auto;
          marginLeft: "0px",
          marginTop: "15px",
          width: "10%",
        }}
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            {error && (
              <Stack sx={{ width: "100%", mb: 2 }} spacing={2}>
                <Alert severity="error">{error}!</Alert>
              </Stack>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="name"
                  required
                  fullWidth
                  id="Name"
                  label="Name"
                  autoComplete="name"
                  onChange={(e) => {
                    setForm({ ...form, name: e.target.value });
                    onChange({ name: "name", value: e.target.value });
                  }}
                />
                {errors.name && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.name}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="mobile"
                  label="Mobile"
                  name="mobile"
                  autoComplete="mobile"
                  onChange={(e) => {
                    setForm({ ...form, mobile: e.target.value });
                    onChange({ name: "mobile", value: e.target.value });
                  }}
                />
                {errors.mobile && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.mobile}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  onChange={(e) => {
                    setForm({ ...form, email: e.target.value });
                    onChange({ name: "email", value: e.target.value });
                  }}
                />
                {errors.email && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.email}!</Alert>
                  </Stack>
                )}
              </Grid>
              {/* <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                />
                            </Grid> */}
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="current_address"
                  label="Current Address"
                  name="current_address"
                  autoComplete="current_address"
                  multiline
                  maxRows={4}
                  onChange={(e) => {
                    onChange({
                      name: "current_address",
                      value: e.target.value,
                    });
                  }}
                />
                {errors.current_address && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.current_address}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="permanent_address"
                  label="Permanent Address"
                  name="permanent_address"
                  autoComplete="permanent_address"
                  multiline
                  maxRows={4}
                  onChange={(e) => {
                    onChange({
                      name: "permanent_address",
                      value: e.target.value,
                    });
                  }}
                />
                {errors.permanent_address && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.permanent_address}!</Alert>
                  </Stack>
                )}
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={usersData}
                  onChange={(e, value) => {
                    setForm({ ...form, role: value.value });
                    onChange({ name: "role", value: value.value });
                  }}
                  renderInput={(params) => (
                    <TextField required {...params} label="Role" />
                  )}
                />
                {errors.role && (
                  <Stack sx={{ width: "100%" }} spacing={2}>
                    <Alert severity="error">{errors.role}!</Alert>
                  </Stack>
                )}
              </Grid>
              {/* <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                                    label="I want to receive inspiration, marketing promotions and updates via email."
                                />
                            </Grid> */}
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading ? true : false}
            >
              {loading ? (
                <CircularProgress
                  sx={{
                    color: "white",
                  }}
                  size={25}
                />
              ) : null}
              Submit
            </Button>
          </Box>
        </Box>
        {/* <Copyright sx={{ mt: 5 }} /> */}
      </Container>
    </ThemeProvider>
  );
}
