import * as React from 'react';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Stack } from '@mui/system';
import { Alert, CircularProgress, Snackbar } from '@mui/material';

export default function ManageRights() {

    const controls = [
        // {
        //     name: 'create',
        //     label: "Allow to Create/Add data."
        // },
        // {
        //     name: 'read',
        //     label: "Allow to Read/Get data."
        // },
        // {
        //     name: 'update',
        //     label: "Allow to Update data."
        // },
        // {
        //     name: 'delete',
        //     label: "Allow to Delete data."
        // },
        {
            name: 'assign',
            label: 'Allow to Assign engineer.'
        },
        {
            name: 'reopen',
            label: 'Allow to Re-Open a Service.'
        },
    ]

    const [state, setState] = React.useState({
        // create: false,
        // read: false,
        // update: false,
        // delete: false,
        assign: false,
        reopen: false,
    });

    const [loading, setLoading] = React.useState(false)

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleChange = (event) => {
        setLoading(true)
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });

        const name = event.target.name
        const value = event.target.checked

        handleUpdate(name, value)

    };

    const handleUpdate = (name, value) => {
        axios.post(`/rights/update`, {
            _id: state._id,
            [name]: value,
        }).then((res) => {
            // setState(res.data.data)
            // console.log(res.data.data);
            setLoading(false)
            setOpen(true)
        }).catch((err) => {
            setLoading(false)
            console.log(err);
        })
    }

    const call = () => {
        axios.post(`/rights/read`).then((res) => {
            if (res.data.status) {
                setState(res.data.data)
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    React.useEffect(() => {

        call()

    }, [])

    return (
        <>
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }} open={open} autoHideDuration={4000} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                        Rights Updated Successfully....
                    </Alert>
                </Snackbar>
            </Stack>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
            }}>

                <FormControl component="fieldset" variant="standard">
                    <FormLabel component="legend" sx={{
                        mt: 2,
                        mb: 2,
                    }}>Manage HelpDesk Rights</FormLabel>
                    <hr style={{
                        // margin: auto;
                        margin: '10px 0',
                        width: '100%',
                        color: "lightgray",
                    }} />
                    <FormGroup>
                        {/* <FormControlLabel
                            control={
                                loading ?
                                    <CircularProgress
                                        size={35}
                                    />
                                    :
                                    <Switch checked={state.create} onChange={handleChange} name="create" />
                            }
                            label="Allow to Create/Add data."
                        />
                        <FormControlLabel
                            control={
                                loading ?
                                    <CircularProgress
                                        size={20}
                                    />
                                    :
                                    <Switch checked={state.read} onChange={handleChange} name="read" />
                            }
                            label="Allow to Read/Get data."
                        />
                        <FormControlLabel
                            control={
                                loading ?
                                    <CircularProgress
                                        size={20}
                                    />
                                    :
                                    <Switch checked={state.update} onChange={handleChange} name="update" />
                            }
                            label="Allow to Update data."
                        />
                        <FormControlLabel
                            control={
                                loading ?
                                    <CircularProgress
                                        size={20}
                                    />
                                    :
                                    <Switch checked={state.delete} onChange={handleChange} name="delete" />
                            }
                            label="Allow to Delete data."
                        /> */}

                        <FormGroup>
                            {controls.map((item, key) => (
                                <FormControlLabel
                                    key={key}
                                    control={
                                        loading ?
                                            <CircularProgress
                                                size={35}
                                            />
                                            :
                                            <Switch checked={state && state[item.name]} onChange={handleChange} name={item.name} />
                                    }
                                    label={item.label}
                                />
                            ))}
                        </FormGroup>

                    </FormGroup>
                    {/* <FormHelperText>Be careful</FormHelperText> */}
                </FormControl>
            </div>
        </>
    );
}