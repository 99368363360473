import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useLocation } from 'react-router-dom';

export default function LabTabs({ data }) {

    const location = useLocation()

    const state = location.state

    const [value, setValue] = React.useState(state !== null ? state.index : "1");

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };    

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="lab API tabs example">
                        {/* <Tab label="Total" value="1" />
                        <Tab label="Pending" value="2" />
                        <Tab label="Closed" value="3" />
                        <Tab label="Assigned" value="4" /> */}
                        {data.map((data, key) => (
                            <Tab label={data.label} value={(key + 1).toString()} key={key} />
                        ))}
                    </TabList>
                </Box>
                {/* <TabPanel value="1">                    
                    <ComplaintsTable title={"Total Services"} />
                </TabPanel>
                <TabPanel value="2">
                    <ComplaintsTable title={"Pending Services"} />
                </TabPanel>
                <TabPanel value="3">
                    <ComplaintsTable title={"Closed Services"} />
                </TabPanel>
                <TabPanel value="4">
                    <ComplaintsTable title={"Assigned Services"} />
                </TabPanel> */}
                {/* <TabPanel value="1">                    
                    <ComplaintsTable title={"Total Services"} />
                </TabPanel> */}
                {data.map((data, key) => (
                    <TabPanel value={(key + 1).toString()} key={key}>
                        {data.component}
                    </TabPanel>
                ))}
            </TabContext>
        </Box>
    );
}