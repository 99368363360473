import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { CircularProgress, IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';

const theme = createTheme();

export default function ResetPassword() {

    const [errors, setErrors] = React.useState({})

    const [loading, setLoading] = React.useState(false)

    const [show, setShow] = React.useState(false)

    const [status, setStatus] = React.useState(false)

    const { token, id } = useParams();

    const handleClickShowPassword = () => {
        setShow(!show);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onChange = ({ name, value }) => {

        if (value !== '') {
            setErrors(prev => {
                return { ...prev, [name]: null };
            });
        }
        else {
            setErrors((prev) => {
                return { ...prev, [name]: 'This field is required....' }
            });
        }

    }

    const handleSubmit = async (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        if (!data.get('password').length) {
            setErrors((prev) => {
                return { ...prev, password: 'Please enter valid password....' }
            });
        }

        if (data.get('password').length) {
            setLoading(true)
            axios.post('/user/resetPassword', {
                id: id,
                token: token,
                password: data.get('password')
            }).then((res) => {
                if (res.data.status) {
                    setStatus(true)
                }
            }).catch((err) => {
                setLoading(false)
                setErrors((prev) => {
                    return { ...prev, password: 'Link Expired....' }
                });
                console.log(err);
            })
        }

    };

    var isLoggedIn = localStorage.getItem("loggedIn")

    React.useEffect(() => {
        if (isLoggedIn) {
            isLoggedIn = JSON.parse(localStorage.getItem("loggedIn"))
            const user = JSON.parse(localStorage.getItem("user"))
            if (isLoggedIn) {
                // window.location.reload()
                // navigate("/Dashboard")
                if (user.role === 'admin') {
                    window.location.replace('/dashboard')
                }
                if (user.role === 'helpdesk') {
                    window.location.replace('/helpdeskdashboard')
                }
                if (user.role === 'engineer') {
                    window.location.replace('/engineerdashboard')
                }
                if (user.role === 'user') {
                    window.location.replace('/customerdashboard')
                }
            }
        }
    }, [])



    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                {status ?
                    <>
                        <Typography sx={{
                            textAlign: 'center',
                            color: 'green'
                        }}>
                            Password Reset success....
                        </Typography>
                        <Button
                            onClick={() => {
                                window.location.replace('/login')
                            }}
                            type="button"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Login
                        </Button>
                    </>
                    :
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography component="h1" variant="h5">
                            Reset Password
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="New Password"
                                type={show ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                onChange={(e) => {
                                    onChange({ name: 'password', value: e.target.value })
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {show ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    ),
                                }}
                            />
                            {errors.password &&
                                <Stack sx={{ width: '100%' }} spacing={2}>
                                    <Alert severity="error">{errors.password}!</Alert>
                                </Stack>
                            }
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                                disabled={loading ? true : false}
                            >
                                {loading ?
                                    <CircularProgress sx={{
                                        color: 'white'
                                    }}
                                        size={25}
                                    />
                                    : null
                                }
                                Submit
                            </Button>
                        </Box>
                    </Box>
                }
            </Container>
        </ThemeProvider>
    );
}