import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import CardWithIcon from '../components/DashCards/CardWithIcon'
import { getComplaints } from '../../../redux/actions/complaintActions'

import BorderAllIcon from '@mui/icons-material/BorderAll';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import PendingIcon from '@mui/icons-material/Pending';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import BorderColorIcon from '@mui/icons-material/BorderColor';

function Dashboard() {

  // const [total, setTotal] = useState(0)
  // const [pending, setPending] = useState(0)
  // const [closed, setClosed] = useState(0)
  // const [assigned, setAssigned] = useState(0)

  var pending = 0, closed = 0, assigned = 0, un_assigned = 0, re_opened = 0, remarked = 0

  const cards = [
    {
      title: 'Total',
      value: (pending + closed + assigned + un_assigned + re_opened),
      background: 'gray',
      color: 'white',
    },
    {
      title: 'Pending',
      value: pending,
      background: 'gray',
      color: 'white',
    },
    {
      title: 'Closed',
      value: closed,
      background: 'gray',
      color: 'white',
    },
    {
      title: 'Assigned',
      value: assigned,
      background: 'gray',
      color: 'white',
    },
    {
      title: 'Un-Assigned',
      value: un_assigned,
      background: 'gray',
      color: 'white',
    },
    {
      title: 'Re-Opened',
      value: re_opened,
      background: 'gray',
      color: 'white',
    },
    {
      title: 'Remarks',
      value: remarked,
      background: 'gray',
      color: 'white',
    },
  ]

  const dispatch = useDispatch()

  const complaints = useSelector(state => state.complaints.complaints)

  const engineerId = localStorage.getItem('user') &&
    JSON.parse(localStorage.getItem('user')) &&
    JSON.parse(localStorage.getItem('user'))._id

  useEffect(() => {

    dispatch(getComplaints('', 'engineer', engineerId))

  }, [])  

  if (complaints && complaints.status) {
    const p = complaints.data.filter(com => com.status === 'pending')
    const c = complaints.data.filter(com => com.status === 'closed')
    const a = complaints.data.filter(com => com.status === 'assigned')
    // const u = complaints.data.filter(com => com.status === 'un-assigned')
    const r = complaints.data.filter(com => com.status === 're-opened')
    const rm = complaints.data.filter(com => com.status === 'remarked')
    // setPending(p.length)
    // setClosed(c)
    // setAssigned(a)
    pending = p.length
    closed = c.length
    assigned = a.length
    // un_assigned = u.length
    re_opened = r.length
    remarked = rm.length
    // complaints.data.forEach(com => {
    //   if (com.status === 'pending') {
    //     setPending(prev => prev + 1)
    //   } else if (com.status === 'closed') {
    //     setClosed(prev => prev + 1)
    //   } else if (com.status === 'assigned') {
    //     setAssigned(prev => prev + 1)
    //   } else {
    //     console.log('none');
    //   }
    // });
  }

  return (
    <div style={{
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      width: '100%',
    }}>
      <CardWithIcon title={"Total"} value={closed + assigned + re_opened + remarked} index={"1"} color={'white'} background={'#b75cfa'} icon={BorderAllIcon} />
      <CardWithIcon title={"Pending"} value={pending} index={"2"} color={'white'} background={'gray'} icon={PendingIcon} />
      <CardWithIcon title={"Closed"} value={closed} index={"2"} color={'white'} background={'green'} icon={DoneAllIcon} />
      <CardWithIcon title={"Assigned"} value={assigned} index={"3"} color={'white'} background={'dodgerblue'} icon={AssignmentIndIcon} />
      {/* <CardWithIcon title={"Un-Assigned"} value={un_assigned} index={"5"} color={'white'} background={'#C70039'} icon={AssignmentLateIcon} /> */}
      <CardWithIcon title={"Re-Opened"} value={re_opened} index={"4"} color={'white'} background={'orangered'} icon={AutorenewIcon} />
      {/* <CardWithIcon title={"Remarks"} value={remarked} index={"5"} color={'white'} background={'indigo'} icon={BorderColorIcon} /> */}

      {/* {cards.map((item, key) => (
        <CardWithIcon title={item.title} value={item.value} index={(key + 1).toString()} color={item.color} background={item.background} />
      ))} */}

    </div>
  )
}

export default Dashboard