import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

function AboutUs() {
  return (
    <div className="home_div">
      <Header />

      <div className="alertDiv1">
        <h2>About Us</h2>
        <p>
          IT Wale services incorporated in 2022 having head office at Faridabad
          (Haryana). Company has been promoted by 2 veterans of IT industries
          Mr. Jitendra Kumar Bharti and Mr. Yogesh Kumar having more than 10
          years’ experience in IT industries. The Company firmly believes that
          Technology can make life simpler of every citizen and helping in good
          Corporate Governance practices ensure efficiency, integrity, honesty
          and accountability and help the Company in its goal to maximize value
          for all its stakeholders.
        </p>
        <p>
          We are an IT Service provider that offers hassle free laptop/desktop
          repair and maintenance service at your doorstep.
        </p>
        <p>
          <b>OUR VISION</b>
        </p>
        <p>
          To be recognized as a trusted technology partner by our clients and
          partners that provides world class technology solutions that exceeds
          our client’s needs and expectations served by our intellectual network
          - our people.
        </p>
        <p>
          <b>OUR MISSION</b>
        </p>
        <p>
          To achieve a highest levels of success by conducting ourselves as an
          organization with Clarity of Thought (in every action we take),
          Objectivity to Facts and Values (results perceived by our customers),
          and Integrity in our Approach and Deeds.
        </p>
        <p>
          <b>OUR VALUES</b>
        </p>
        <p>
          Quality, Innovation, Excellence and Customer Satisfaction are the four
          pillars on which the organization rests.
        </p>
      </div>

      <Footer />
    </div>
  );
}

export default AboutUs;
