// import React from "react";
// import MUIDataTable from "mui-datatables";
// import Table from "@mui/material/Table";
// import TableContainer from "@mui/material/TableContainer";
// import Paper from "@mui/material/Paper";
// import EditUserForm from "./EditUserForm";

// const ExpandableRowTable = props => {
//     const columns = [
//         {
//             name: "Name"
//         },
//         {
//             name: "Title"
//         },
//         {
//             name: "Location"
//         },
//         {
//             name: "Age"
//         },
//         {
//             name: "Salary"
//         }
//     ];
//     const data = [
//         ["Gabby George", "Business Analyst", "Minneapolis", 30, 100000],
//         ["Business Analyst", "Business Consultant", "Dallas", 55, 200000],
//         ["Jaden Collins", "Attorney", "Santa Ana", 27, 500000],
//         ["Franky Rees", "Business Analyst", "St. Petersburg", 22, 50000],
//         ["Aaren Rose", "Business Consultant", "Toledo", 28, 75000],
//         ["Blake Duncan", "Business Management Analyst", "San Diego", 65, 94000],
//         ["Frankie Parry", "Agency Legal Counsel", "Jacksonville", 71, 210000],
//         ["Lane Wilson", "Commercial Specialist", "Omaha", 19, 65000],
//         ["Robin Duncan", "Business Analyst", "Los Angeles", 20, 77000],
//         ["Mel Brooks", "Business Consultant", "Oklahoma City", 37, 135000],
//         ["Harper White", "Attorney", "Pittsburgh", 52, 420000],
//         ["Kris Humphrey", "Agency Legal Counsel", "Laredo", 30, 150000],
//         ["Frankie Long", "Industrial Analyst", "Austin", 31, 170000],
//         ["Brynn Robbins", "Business Analyst", "Norfolk", 22, 90000],
//         ["Justice Mann", "Business Consultant", "Chicago", 24, 133000],
//         ["Addison Navarro", "Business Management Analyst", "New York", 50, 295000],
//         ["Jesse Welch", "Agency Legal Counsel", "Seattle", 28, 200000],
//         ["Eli Mejia", "Commercial Specialist", "Long Beach", 65, 400000],
//         ["Gene Leblanc", "Industrial Analyst", "Hartford", 34, 110000],
//         ["Danny Leon", "Computer Scientist", "Newark", 60, 220000],
//         ["Lane Lee", "Corporate Counselor", "Cincinnati", 52, 180000],
//         ["Jesse Hall", "Business Analyst", "Baltimore", 44, 99000],
//         ["Danni Hudson", "Agency Legal Counsel", "Tampa", 37, 90000],
//         ["Terry Macdonald", "Commercial Specialist", "Miami", 39, 140000],
//         ["Justice Mccarthy", "Attorney", "Tucson", 26, 330000],
//         ["Silver Carey", "Computer Scientist", "Memphis", 47, 250000],
//         ["Franky Miles", "Industrial Analyst", "Buffalo", 49, 190000],
//         ["Glen Nixon", "Corporate Counselor", "Arlington", 44, 80000],
//         [
//             "Gabby Strickland",
//             "Business Process Consultant",
//             "Scottsdale",
//             26,
//             45000
//         ],
//         ["Mason Ray", "Computer Scientist", "San Francisco", 39, 142000]
//     ];

//     function createData(name, calories, fat, carbs, protein) {
//         return { name, calories, fat, carbs, protein };
//     }

//     const rows = [
//         createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//         createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//         createData("Eclair", 262, 16.0, 24, 6.0),
//         createData("Cupcake", 305, 3.7, 67, 4.3),
//         createData("Gingerbread", 356, 16.0, 49, 3.9)
//     ];

//     const options = {
//         filter: true,
//         onFilterChange: (changedColumn, filterList) => {
//             console.log(changedColumn, filterList);
//         },
//         selectableRows: "single",
//         filterType: "dropdown",
//         responsive: "scrollMaxHeight",
//         rowsPerPage: 10,
//         expandableRows: true,
//         renderExpandableRow: (rowData, rowMeta) => {
//             // console.log(rowData, rowMeta);
//             return (
//                 <React.Fragment>
//                     <tr>
//                         <td colSpan={6}>
//                             <TableContainer component={Paper}>
//                                 <Table style={{ minWidth: "650" }} aria-label="simple table">
//                                     <EditUserForm />
//                                 </Table>
//                             </TableContainer>
//                         </td>
//                     </tr>
//                 </React.Fragment>
//             );
//         },
//         page: 1
//     };

//     return (
//         <MUIDataTable
//             title={"Engineers list"}
//             data={data}
//             columns={columns}
//             options={options}
//         />
//     );
// };

// export default ExpandableRowTable;

// import React from "react";
// import MUIDataTable from "mui-datatables";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import Button from '@mui/material/Button';
// import SendIcon from '@mui/icons-material/Send';

// // const Card = () => (
// //   <tr>
// //     <td className="fullWidth">
// //       <h1>
// //         lorem ipsum dorel em quol acee, vion, bloolw, wafeo, feiwjfoiew,
// //         foiwejifowefjweoi, fewjoewjfowei, fwefwefewfewfewf
// //       </h1>
// //     </td>
// //   </tr>
// // );

// const ExpandableRowTable = (props) => {

//     const columns = [
//         {
//             name: "Name"
//         },
//         {
//             name: "Title"
//         },
//         {
//             name: "Location"
//         },
//         {
//             name: "Age"
//         },
//         {
//             name: "Salary"
//         },
//     ];
//     const data = [
//         ["Gabby George", "Business Analyst", "Minneapolis", 30, 100000],
//         ["Business Analyst", "Business Consultant", "Dallas", 55, 200000],
//         ["Jaden Collins", "Attorney", "Santa Ana", 27, 500000],
//         ["Franky Rees", "Business Analyst", "St. Petersburg", 22, 50000],
//         ["Aaren Rose", "Business Consultant", "Toledo", 28, 75000],
//         ["Blake Duncan", "Business Management Analyst", "San Diego", 65, 94000],
//         ["Frankie Parry", "Agency Legal Counsel", "Jacksonville", 71, 210000],
//         ["Lane Wilson", "Commercial Specialist", "Omaha", 19, 65000],
//         ["Robin Duncan", "Business Analyst", "Los Angeles", 20, 77000],
//         ["Mel Brooks", "Business Consultant", "Oklahoma City", 37, 135000],
//         ["Harper White", "Attorney", "Pittsburgh", 52, 420000],
//         ["Kris Humphrey", "Agency Legal Counsel", "Laredo", 30, 150000],
//         ["Frankie Long", "Industrial Analyst", "Austin", 31, 170000],
//         ["Brynn Robbins", "Business Analyst", "Norfolk", 22, 90000],
//         ["Justice Mann", "Business Consultant", "Chicago", 24, 133000],
//         ["Addison Navarro", "Business Management Analyst", "New York", 50, 295000],
//         ["Jesse Welch", "Agency Legal Counsel", "Seattle", 28, 200000],
//         ["Eli Mejia", "Commercial Specialist", "Long Beach", 65, 400000],
//         ["Gene Leblanc", "Industrial Analyst", "Hartford", 34, 110000],
//         ["Danny Leon", "Computer Scientist", "Newark", 60, 220000],
//         ["Lane Lee", "Corporate Counselor", "Cincinnati", 52, 180000],
//         ["Jesse Hall", "Business Analyst", "Baltimore", 44, 99000],
//         ["Danni Hudson", "Agency Legal Counsel", "Tampa", 37, 90000],
//         ["Terry Macdonald", "Commercial Specialist", "Miami", 39, 140000],
//         ["Justice Mccarthy", "Attorney", "Tucson", 26, 330000],
//         ["Silver Carey", "Computer Scientist", "Memphis", 47, 250000],
//         ["Franky Miles", "Industrial Analyst", "Buffalo", 49, 190000],
//         ["Glen Nixon", "Corporate Counselor", "Arlington", 44, 80000],
//         [
//             "Gabby Strickland",
//             "Business Process Consultant",
//             "Scottsdale",
//             26,
//             45000
//         ],
//         ["Mason Ray", "Computer Scientist", "San Francisco", 39, 142000]
//     ];

//     function createData(name, calories, fat, carbs, protein) {
//         return { name, calories, fat, carbs, protein };
//     }

//     const rows = [
//         createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//         createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//         createData("Eclair", 262, 16.0, 24, 6.0),
//         createData("Cupcake", 305, 3.7, 67, 4.3),
//         createData("Gingerbread", 356, 16.0, 49, 3.9)
//     ];

//     const options = {
//         filter: true,
//         onFilterChange: (changedColumn, filterList) => {
//             console.log(changedColumn, filterList);
//         },
//         onRowsDelete: (e) => { console.log(e.data) },
//         selectableRows: "multiple",
//         filterType: "dropdown",
//         responsive: "scrollMaxHeight",
//         rowsPerPage: 5,
//         // rowsPerPageOptions: [5, 10, data.length],
//         expandableRows: true,
//         renderExpandableRow: (rowData, rowMeta) => {

//             // console.log(rowData, rowMeta);

//             return (
//                 <React.Fragment>
//                     <tr>
//                         <td colSpan={6}>

//                             <div style={{
//                                 display: 'flex',
//                                 margin: "20px 0",
//                                 justifyContent: 'center'
//                             }}>
//                                 <Button variant="contained" size="small" endIcon={<SendIcon />}>
//                                     Click to Assign This Complaint
//                                 </Button>
//                             </div>

//                             <TableContainer component={Paper}>
//                                 <Table style={{ minWidth: "650" }} aria-label="simple table">
//                                     <TableHead>
//                                         <TableRow>
//                                             <TableCell>Dessert (100g serving)</TableCell>
//                                             <TableCell align="right">Calories</TableCell>
//                                             <TableCell align="right">Fat&nbsp;(g)</TableCell>
//                                             <TableCell align="right">Carbs&nbsp;(g)</TableCell>
//                                             <TableCell align="right">Calories</TableCell>
//                                         </TableRow>
//                                     </TableHead>
//                                     <TableBody>
//                                         {rows.map((row, key) => (
//                                             <TableRow key={key}>
//                                                 <TableCell component="th" scope="row">
//                                                     {row.name}
//                                                 </TableCell>
//                                                 <TableCell align="right">{row.calories}</TableCell>
//                                                 <TableCell align="right">{row.fat}</TableCell>
//                                                 <TableCell align="right">{row.carbs}</TableCell>
//                                                 <TableCell align="right">{row.protein}</TableCell>
//                                             </TableRow>
//                                         ))}
//                                     </TableBody>
//                                 </Table>
//                             </TableContainer>
//                         </td>
//                     </tr>
//                 </React.Fragment>
//             );
//         },
//         page: 1
//     };

//     return (
//         <MUIDataTable
//             title={props.title}
//             data={data}
//             columns={columns}
//             options={options}
//         />
//     );
// };

// export default ExpandableRowTable;

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { CircularProgress, Collapse, TextField } from "@mui/material";

import EditUserForm from "./EditUserForm";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import axios from "axios";

import CloseIcon from "@mui/icons-material/Close";

function createData(
  id,
  name,
  email,
  mobile,
  image,
  deactivated,
  current_address,
  permanent_address,
  city,
  state
) {
  return {
    id,
    name,
    email,
    mobile,
    image,
    deactivated,
    current_address,
    permanent_address,
    city,
    state,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
  },
  {
    id: "mobile",
    numeric: true,
    disablePadding: false,
    label: "Mobile",
  },
  {
    id: "current_address",
    numeric: true,
    disablePadding: false,
    label: "Current Address",
  },
  {
    id: "permanent_address",
    numeric: true,
    disablePadding: false,
    label: "Permanent Address",
  },
  {
    id: "city",
    numeric: true,
    disablePadding: false,
    label: "City",
  },
  {
    id: "state",
    numeric: true,
    disablePadding: false,
    label: "State",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={"left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

let ids = [];

function EnhancedTableToolbar(props) {
  const { numSelected, title, originalRows, setRows } = props;

  const [loading, setLoading] = React.useState(false);

  const [searched, setSearched] = useState("");

  const requestSearch = (e) => {
    const filteredRows = originalRows.filter((row) => {
      return (
        row.id.toLowerCase().includes(e.target.value.toLowerCase()) ||
        row.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        row.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
        row.city.toLowerCase().includes(e.target.value.toLowerCase()) ||
        row.state.toLowerCase().includes(e.target.value.toLowerCase()) 
        
      );
    });
    setSearched(e.target.value);
    setRows(filteredRows);
    // setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    setRows(originalRows);
  };

  const handleDeleteSelected = async () => {
    // setLoading(true)
    // ids.forEach(async id => {
    //   axios.post('/user/delete', { id }).then((res) => {
    //     console.log("success");
    //   }).catch((err) => {
    //     console.log(err);
    //   })
    // });
    setLoading(true);
    for (const id of ids) {
      //   axios.post('/user/delete', { id }).then((res) => {
      //     console.log("success");
      //   }).catch((err) => {
      //     console.log(err);
      //   })
      await axios.post("/user/delete", { id });
    }
    setLoading(false);
    // setLoading(false)
    window.location.reload();
  };
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton disabled={loading ? true : false}>
            {loading && (
              <CircularProgress
                sx={{
                  color: "dogerblue",
                }}
                size={25}
              />
            )}
            <DeleteIcon onClick={handleDeleteSelected} />
          </IconButton>
        </Tooltip>
      ) : (
        <TextField
          margin="dense"
          fullWidth
          id="standard-basic"
          label="Search"
          variant="standard"
          sx={{
            width: "30%",
          }}
          value={searched}
          onChange={requestSearch}
          InputProps={{
            endAdornment: (
              <CloseIcon
                onClick={cancelSearch}
                sx={{
                  cursor: "pointer",
                  fontSize: "18px",
                }}
              />
            ),
          }}
        />
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function CollapseRow(props) {
  const { row, isItemSelected, labelId, onClick } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      {/* <tr>
                <td colSpan={6}>
                    <div style={{
                        display: 'flex',
                        margin: "20px 0",
                        justifyContent: 'center'
                    }}>
                        <Button variant="contained" size="small" endIcon={<SendIcon />}>
                            Click to Assign This Complaint
                        </Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table style={{ minWidth: "650" }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Dessert (100g serving)</TableCell>
                                    <TableCell align="right">Calories</TableCell>
                                    <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                    <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                    <TableCell align="right">Calories</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, key) => (
                                    <TableRow key={key}>
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.calories}</TableCell>
                                        <TableCell align="right">{row.fat}</TableCell>
                                        <TableCell align="right">{row.carbs}</TableCell>
                                        <TableCell align="right">{row.protein}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </td>
            </tr> */}

      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <Checkbox
            // onClick={(event) => handleClick(event, row.name)}
            onClick={onClick}
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>

        <TableCell align="left">{row.id}</TableCell>
        <TableCell align="left">{row.name}</TableCell>
        <TableCell align="left">{row.email}</TableCell>
        <TableCell align="left">{row.mobile}</TableCell>
        <TableCell align="left">{row.current_address}</TableCell>
        <TableCell align="left">{row.permanent_address}</TableCell>
        <TableCell align="left">{row.city}</TableCell>
        <TableCell align="left">{row.state}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{
              margin: "25px auto",
            }}
          >
            <EditUserForm user={row} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function UsersTable({ title, role }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [copyRows, setCopyRows] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      ids = newSelected;
      return;
    }
    setSelected([]);
    ids = [];
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    ids = newSelected;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const getData = () => {
    axios
      .post("/user/getallusers", {
        role: role,
      })
      .then((res) => {
        // console.log(res);
        const data = [];
        res.data.data.forEach((e) => {
          data.push(
            createData(
              e._id,
              e.name,
              e.email,
              e.mobile,
              e.image,
              e.deactivated,
              e.current_address,
              e.permanent_address,
              e.city,
              e.state,
            )
          );
        });
        setRows(data);
        setCopyRows(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getData();
  }, [role]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          originalRows={copyRows}
          setRows={setRows}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    // <TableRow
                    //     hover
                    //     role="checkbox"
                    //     // aria-checked={isItemSelected}
                    //     tabIndex={-1}
                    //     key={row.name}
                    // // selected={isItemSelected}
                    // >
                    //     <TableCell padding="checkbox">
                    //         <Checkbox
                    //             onClick={(event) => handleClick(event, row.name)}
                    //             color="primary"
                    //             checked={isItemSelected}
                    //             inputProps={{
                    //                 'aria-labelledby': labelId,
                    //             }}
                    //         />
                    //     </TableCell>
                    //     <TableCell
                    //         component="th"
                    //         id={labelId}
                    //         scope="row"
                    //         padding="none"
                    //     >
                    //         {row.name}
                    //     </TableCell>
                    //     <TableCell align="right">{row.calories}</TableCell>
                    //     <TableCell align="right">{row.fat}</TableCell>
                    //     <TableCell align="right">{row.carbs}</TableCell>
                    //     <TableCell align="right">{row.protein}</TableCell>
                    // </TableRow>

                    <CollapseRow
                      row={row}
                      key={index}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      onClick={(event) => handleClick(event, row.id)}
                    />
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
