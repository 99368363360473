import { ActionTypes } from "../contants/action-types";


const initial = {
    rights: {},
}

export const rightsReducer = (state = initial, { type, payload }) => {

    switch (type) {
        case ActionTypes.RIGHTS_GET:
            return { ...state, error: false };
        case ActionTypes.RIGHTS_GET_SEUCCESS:
            return { ...state, rights: payload, error: false };
        case ActionTypes.RIGHTS_GET_FAILED:
            return { ...state, error: true };
        default:
            return state;
    }
};
