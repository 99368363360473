// import React from "react";
// import MUIDataTable from "mui-datatables";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import Button from '@mui/material/Button';
// import SendIcon from '@mui/icons-material/Send';

// // const Card = () => (
// //   <tr>
// //     <td className="fullWidth">
// //       <h1>
// //         lorem ipsum dorel em quol acee, vion, bloolw, wafeo, feiwjfoiew,
// //         foiwejifowefjweoi, fewjoewjfowei, fwefwefewfewfewf
// //       </h1>
// //     </td>
// //   </tr>
// // );

// const ExpandableRowTable = (props) => {

//     const columns = [
//         {
//             name: "Name"
//         },
//         {
//             name: "Title"
//         },
//         {
//             name: "Location"
//         },
//         {
//             name: "Age"
//         },
//         {
//             name: "Salary"
//         },
//     ];
//     const data = [
//         ["Gabby George", "Business Analyst", "Minneapolis", 30, 100000],
//         ["Business Analyst", "Business Consultant", "Dallas", 55, 200000],
//         ["Jaden Collins", "Attorney", "Santa Ana", 27, 500000],
//         ["Franky Rees", "Business Analyst", "St. Petersburg", 22, 50000],
//         ["Aaren Rose", "Business Consultant", "Toledo", 28, 75000],
//         ["Blake Duncan", "Business Management Analyst", "San Diego", 65, 94000],
//         ["Frankie Parry", "Agency Legal Counsel", "Jacksonville", 71, 210000],
//         ["Lane Wilson", "Commercial Specialist", "Omaha", 19, 65000],
//         ["Robin Duncan", "Business Analyst", "Los Angeles", 20, 77000],
//         ["Mel Brooks", "Business Consultant", "Oklahoma City", 37, 135000],
//         ["Harper White", "Attorney", "Pittsburgh", 52, 420000],
//         ["Kris Humphrey", "Agency Legal Counsel", "Laredo", 30, 150000],
//         ["Frankie Long", "Industrial Analyst", "Austin", 31, 170000],
//         ["Brynn Robbins", "Business Analyst", "Norfolk", 22, 90000],
//         ["Justice Mann", "Business Consultant", "Chicago", 24, 133000],
//         ["Addison Navarro", "Business Management Analyst", "New York", 50, 295000],
//         ["Jesse Welch", "Agency Legal Counsel", "Seattle", 28, 200000],
//         ["Eli Mejia", "Commercial Specialist", "Long Beach", 65, 400000],
//         ["Gene Leblanc", "Industrial Analyst", "Hartford", 34, 110000],
//         ["Danny Leon", "Computer Scientist", "Newark", 60, 220000],
//         ["Lane Lee", "Corporate Counselor", "Cincinnati", 52, 180000],
//         ["Jesse Hall", "Business Analyst", "Baltimore", 44, 99000],
//         ["Danni Hudson", "Agency Legal Counsel", "Tampa", 37, 90000],
//         ["Terry Macdonald", "Commercial Specialist", "Miami", 39, 140000],
//         ["Justice Mccarthy", "Attorney", "Tucson", 26, 330000],
//         ["Silver Carey", "Computer Scientist", "Memphis", 47, 250000],
//         ["Franky Miles", "Industrial Analyst", "Buffalo", 49, 190000],
//         ["Glen Nixon", "Corporate Counselor", "Arlington", 44, 80000],
//         [
//             "Gabby Strickland",
//             "Business Process Consultant",
//             "Scottsdale",
//             26,
//             45000
//         ],
//         ["Mason Ray", "Computer Scientist", "San Francisco", 39, 142000]
//     ];

//     function createData(name, calories, fat, carbs, protein) {
//         return { name, calories, fat, carbs, protein };
//     }

//     const rows = [
//         createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//         createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//         createData("Eclair", 262, 16.0, 24, 6.0),
//         createData("Cupcake", 305, 3.7, 67, 4.3),
//         createData("Gingerbread", 356, 16.0, 49, 3.9)
//     ];

//     const options = {
//         filter: true,
//         onFilterChange: (changedColumn, filterList) => {
//             console.log(changedColumn, filterList);
//         },
//         onRowsDelete: (e) => { console.log(e.data) },
//         selectableRows: "multiple",
//         filterType: "dropdown",
//         responsive: "scrollMaxHeight",
//         rowsPerPage: 5,
//         // rowsPerPageOptions: [5, 10, data.length],
//         expandableRows: true,
//         renderExpandableRow: (rowData, rowMeta) => {

//             // console.log(rowData, rowMeta);

//             return (
//                 <React.Fragment>
//                     <tr>
//                         <td colSpan={6}>

//                             <div style={{
//                                 display: 'flex',
//                                 margin: "20px 0",
//                                 justifyContent: 'center'
//                             }}>
//                                 <Button variant="contained" size="small" endIcon={<SendIcon />}>
//                                     Click to Assign This Complaint
//                                 </Button>
//                             </div>

//                             <TableContainer component={Paper}>
//                                 <Table style={{ minWidth: "650" }} aria-label="simple table">
//                                     <TableHead>
//                                         <TableRow>
//                                             <TableCell>Dessert (100g serving)</TableCell>
//                                             <TableCell align="right">Calories</TableCell>
//                                             <TableCell align="right">Fat&nbsp;(g)</TableCell>
//                                             <TableCell align="right">Carbs&nbsp;(g)</TableCell>
//                                             <TableCell align="right">Calories</TableCell>
//                                         </TableRow>
//                                     </TableHead>
//                                     <TableBody>
//                                         {rows.map((row, key) => (
//                                             <TableRow key={key}>
//                                                 <TableCell component="th" scope="row">
//                                                     {row.name}
//                                                 </TableCell>
//                                                 <TableCell align="right">{row.calories}</TableCell>
//                                                 <TableCell align="right">{row.fat}</TableCell>
//                                                 <TableCell align="right">{row.carbs}</TableCell>
//                                                 <TableCell align="right">{row.protein}</TableCell>
//                                             </TableRow>
//                                         ))}
//                                     </TableBody>
//                                 </Table>
//                             </TableContainer>
//                         </td>
//                     </tr>
//                 </React.Fragment>
//             );
//         },
//         page: 1
//     };

//     return (
//         <MUIDataTable
//             title={props.title}
//             data={data}
//             columns={columns}
//             options={options}
//         />
//     );
// };

// export default ExpandableRowTable;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import {
  Alert,
  Autocomplete,
  Button,
  CircularProgress,
  Collapse,
  Container,
  CssBaseline,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Modal,
  Stack,
  TextField,
} from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SubLabTabs from "./SubLabTabs";
import { useDispatch, useSelector } from "react-redux";
import { getComplaints } from "../../../redux/actions/complaintActions";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { getUsers } from "../../../redux/actions/userActions";
import axios from "axios";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { storage } from "../../../firebase";
import { v4 } from "uuid";

import CloseIcon from "@mui/icons-material/Close";
import { City, State } from "country-state-city";
import PDF from "../../../pages/Pdf";

function createData(
  id,
  name,
  description,
  model_number,
  serial_number,
  mobile,
  address,
  status,
  history,
  engineer,
  images,
  userId,
  remarks,
  state,
  city,
  price
) {
  return {
    id,
    name,
    description,
    model_number,
    serial_number,
    mobile,
    address,
    status,
    history,
    engineer,
    images,
    userId,
    remarks,
    state,
    city,
    price,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "id",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  // {
  //     id: 'description',
  //     numeric: false,
  //     disablePadding: false,
  //     label: 'Description',
  // },
  // {
  //     id: 'model_number',
  //     numeric: false,
  //     disablePadding: false,
  //     label: 'Model Number',
  // },
  // {
  //     id: 'serial_number',
  //     numeric: false,
  //     disablePadding: false,
  //     label: 'Serial Number',
  // },
  {
    id: "mobile",
    numeric: false,
    disablePadding: false,
    label: "Mobile",
  },
  {
    id: "address",
    numeric: false,
    disablePadding: false,
    label: "Visit Address",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell, key) => (
          <TableCell
            key={key}
            align={"center"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

let ids = [];

function EnhancedTableToolbar(props) {
  const { numSelected, title, originalRows, setRows } = props;
  console.log(originalRows);
  const [loading, setLoading] = React.useState(false);

  const [searched, setSearched] = useState("");

  const requestSearch = (e) => {
    const filteredRows = originalRows.filter((row) => {
      return (
        row.id.toLowerCase().includes(e.target.value.toLowerCase()) ||
        row.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        row.mobile.toLowerCase().includes(e.target.value.toLowerCase()) ||
        row.address.toLowerCase().includes(e.target.value.toLowerCase()) ||
        row.status.toLowerCase().includes(e.target.value.toLowerCase())
      );
    });
    setSearched(e.target.value);
    setRows(filteredRows);
    // setRows(filteredRows);
  };

  const cancelSearch = () => {
    setSearched("");
    setRows(originalRows);
  };

  const handleDeleteSelected = async () => {
    setLoading(true);
    for (const id of ids) {
      await axios.post("/complaint/delete", { id });
    }
    setLoading(false);
    window.location.reload();
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {title}
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton disabled={loading ? true : false}>
            {loading && (
              <CircularProgress
                sx={{
                  color: "dogerblue",
                }}
                size={25}
              />
            )}
            <DeleteIcon onClick={handleDeleteSelected} />
          </IconButton>
        </Tooltip>
      ) : (
        <TextField
          margin="dense"
          fullWidth
          id="standard-basic"
          label="Search"
          variant="standard"
          sx={{
            width: "30%",
          }}
          value={searched}
          onChange={requestSearch}
          InputProps={{
            endAdornment: (
              <CloseIcon
                onClick={cancelSearch}
                sx={{
                  cursor: "pointer",
                  fontSize: "18px",
                }}
              />
            ),
          }}
        />
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function CollapseRow(props) {
  const { row, isItemSelected, labelId, onClick } = props;
  const [open, setOpen] = React.useState(false);

  const setBg = (status) => {
    if (status === "pending") {
      return "gray";
    }
    if (status === "closed") {
      return "green";
    }
    if (status === "assigned") {
      return "dodgerblue";
    }
    if (status === "re-opened") {
      return "orangered";
    }
    if (status === "un-assigned") {
      return "#C70039 ";
    }
    if (status === "remarked") {
      return "indigo ";
    }
  };

  const AssignEngineer = () => {
    let usersData = [];

    const [form, setForm] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [errors, setErrors] = React.useState({});

    const onChange = ({ name, value }) => {
      if (value !== "") {
        setErrors((prev) => {
          return { ...prev, [name]: null };
        });
      } else {
        console.log(name);
        setErrors((prev) => {
          return { ...prev, [name]: "This field is required...." };
        });
      }
    };

    const handleSubmit = (event) => {
      event.preventDefault();

      if (!form.userId || !form.userId.length) {
        setErrors((prev) => {
          return { ...prev, userId: "Please select valid User Id...." };
        });
      }

      const d = new Date().toString();
      const i = d.indexOf(" GMT");
      const date = d.slice(0, i);

      const history = [...row.history, { date: date, status: "assigned" }];

      const engineer = usersData.filter((user) => user.id === form.userId);

      setLoading(true);
      axios
        .post("/complaint/update", {
          id: row.id,
          status: "assigned",
          engineer: [
            {
              id: engineer[0].id,
              name: engineer[0].name,
              email: engineer[0].label,
              mobile: engineer[0].mobile,
            },
          ],
          engineerId: engineer[0].id,
          history: history,
        })
        .then((res) => {
          if (res.data.status) {
            console.log("success");
            window.location.reload();
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    const dispatch = useDispatch();

    const users = useSelector((state) => state.users.usersData);

    React.useEffect(() => {
      dispatch(getUsers("engineer"));
      return () => {};
    }, []);

    if (users.status) {
      users.data.forEach((element) => {
        usersData.push({
          label: element.email,
          id: element._id,
          name: element.name,
          mobile: element.mobile,
        });
      });
    }

    return (
      <div
        style={{
          display: "flex",
          margin: "20px 0",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Grid item xs={12}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={usersData}
            onChange={(e, value) => {
              setForm({ ...form, userId: value.id });
              onChange({ name: "userId", value: value.id });
            }}
            renderInput={(params) => (
              <TextField required {...params} label="User Id/Email" />
            )}
          />
          <Typography
            sx={{
              color: "green",
              mt: 1,
              mb: 1,
            }}
          >
            {""}
          </Typography>
          {errors.userId && (
            <Stack sx={{ width: "100%" }} spacing={2}>
              <Alert severity="error">{errors.userId}!</Alert>
            </Stack>
          )}
          <br />
        </Grid>

        <Button
          onClick={handleSubmit}
          variant="contained"
          size="small"
          endIcon={<SendIcon />}
          disabled={loading ? true : false}
        >
          {loading ? (
            <CircularProgress
              sx={{
                color: "white",
              }}
              size={25}
            />
          ) : null}
          Click to Assign This Complaint
        </Button>
      </div>
    );
  };

  const EngineerTable = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: "auto" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">Email</TableCell>
              <TableCell align="left">Mobile</TableCell>
            </TableRow>
          </TableHead>
          {row.engineer.length ? (
            <TableBody>
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.engineer[0].id}
                </TableCell>
                <TableCell align="left">{row.engineer[0].name}</TableCell>
                <TableCell align="left">{row.engineer[0].email}</TableCell>
                <TableCell align="left">{row.engineer[0].mobile}</TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody></TableBody>
          )}
        </Table>
      </TableContainer>
    );
  };

  const HistoryComponent = () => {
    const [remark, setRemark] = useState("");

    const style = {
      position: "absolute",
      top: "30%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      // border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = (val) => {
      setOpen(true);
      setRemark(val);
    };
    const handleClose = () => {
      setOpen(false);
      setRemark("");
    };
    return (
      <TableContainer component={Paper}>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Remarks
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              {remark}
            </Typography>
          </Box>
        </Modal>
        <Table sx={{ minWidth: "auto" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              {/* <TableCell>Remark</TableCell> */}
              <TableCell>{""}</TableCell>
              <TableCell align="left">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {row.history.map((row, key) => (
              <TableRow
                key={key}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell
                  sx={{
                    width: 300,
                  }}
                  component="th"
                  scope="row"
                >
                  {row.date}
                </TableCell>
                {/* <TableCell align="left">
                  {row.remark ? row.remark : "No Remark"}
                </TableCell> */}
                <TableCell align="left">{""}</TableCell>
                <TableCell align="left">
                  <div
                    onClick={() => {
                      handleOpen(row.remark ? row.remark : "No Remarks");
                    }}
                    style={{
                      background: setBg(row.status),
                      textAlign: "center",
                      padding: "5px",
                      borderRadius: "15px",
                      color: "white",
                      fontWeight: "700",
                      width: "50%",
                      cursor: "pointer",
                    }}
                  >
                    {row.status.toUpperCase()}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const EditComplaint = () => {
    const usersData = [];

    const [imageUrls, setImageUrls] = React.useState(row.images);

    const [form, setForm] = React.useState(row);

    const [errors, setErrors] = React.useState({});

    const [loading, setLoading] = React.useState(false);

    const [remark, setRemark] = useState("");

    const [states, setStates] = useState([]);

    const [cities, setCities] = useState([]);

    const [show, setShow] = useState(true);

    const onImageUpload = async (e) => {
      setImageUrls([]);
      const files = e.target.files;
      for (const file of files) {
        await uploadFile(file);
      }
    };

    const uploadFile = (file) => {
      const imageUpload = file;
      const imageRef = ref(storage, `services/${imageUpload.name + v4()}`);
      uploadBytes(imageRef, imageUpload).then((snapshot) => {
        getDownloadURL(snapshot.ref).then((url) => {
          // const newArr = [...form.images]
          // newArr.push(url)
          // setForm({ ...form, images: newArr });
          setImageUrls((prev) => [...prev, url]);
        });
      });
    };

    const onChange = ({ name, value }) => {
      if (value !== "") {
        if (name === "mobile") {
          var pattern = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/;

          // const num = Number(value)

          if (pattern.test(value)) {
            setErrors((prev) => {
              return { ...prev, [name]: null };
            });
          } else {
            setErrors((prev) => {
              return { ...prev, [name]: "Please enter valid mobile number" };
            });
          }
        } else {
          setErrors((prev) => {
            return { ...prev, [name]: null };
          });
        }
      } else {
        setErrors((prev) => {
          return { ...prev, [name]: "This field is required...." };
        });
      }
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);

      if (!data.get("name").length) {
        setErrors((prev) => {
          return { ...prev, name: "Please enter valid name...." };
        });
      }
      if (!data.get("description").length) {
        setErrors((prev) => {
          return { ...prev, description: "Please enter valid password...." };
        });
      }
      if (!data.get("model_number").length) {
        setErrors((prev) => {
          return {
            ...prev,
            model_number: "Please enter valid model number....",
          };
        });
      }
      if (!data.get("mobile") || !data.get("mobile").length) {
        return setErrors((prev) => {
          return { ...prev, mobile: "Please enter valid mobile number...." };
        });
      }
      if (!data.get("address").length) {
        setErrors((prev) => {
          return { ...prev, address: "Please enter valid address...." };
        });
      }
      if (!form.userId || !form.userId.length) {
        setErrors((prev) => {
          return { ...prev, userId: "Please select valid User Id...." };
        });
      }
      if (!form.state || !form.state.length) {
        return setErrors((prev) => {
          return { ...prev, state: "Please select valid state...." };
        });
      }
      if (!form.city || !form.city.length) {
        return setErrors((prev) => {
          return { ...prev, city: "Please select valid city...." };
        });
      }

      setLoading(true);
      axios
        .post("/complaint/update", {
          id: form.id,
          name: data.get("name"),
          description: data.get("description"),
          model_number: data.get("model_number"),
          serial_number: data.get("serial_number"),
          mobile: data.get("mobile"),
          state: form.state,
          city: form.city,
          address: data.get("address"),
          userId: form.userId,
          images: imageUrls,
        })
        .then((res) => {
          if (res.data.status) {
            console.log("success");
            window.location.reload();
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    const handleDelete = () => {
      setLoading(true);
      axios
        .post("/complaint/delete", {
          id: form.id,
        })
        .then((res) => {
          if (res.data.status) {
            console.log("success");
            window.location.reload();
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    const handleUpdate = (status) => (event) => {
      event.preventDefault();

      const d = new Date().toString();
      const i = d.indexOf(" GMT");
      const date = d.slice(0, i);

      const history = [...row.history, { date: date, status: status }];

      setLoading(true);
      axios
        .post("/complaint/update", {
          id: row.id,
          status: status,
          history: history,
        })
        .then((res) => {
          if (res.data.status) {
            console.log("success");
            window.location.reload();
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    const handleClose = (event) => {
      event.preventDefault();

      const d = new Date().toString();
      const i = d.indexOf(" GMT");
      const date = d.slice(0, i);

      const history = [...row.history, { date: date, status: "closed" }];

      setLoading(true);
      axios
        .post("/complaint/update", {
          id: row.id,
          status: "closed",
          history: history,
        })
        .then((res) => {
          if (res.data.status) {
            console.log("success");
            window.location.reload();
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    const handleReOpen = () => {
      const d = new Date().toString();
      const i = d.indexOf(" GMT");
      const date = d.slice(0, i);

      const history = [
        ...row.history,
        { date: date, remark: remark, status: "re-opened" },
      ];

      setLoading(true);
      axios
        .post("/complaint/update", {
          id: row.id,
          status: "re-opened",
          history: history,
        })
        .then((res) => {
          if (res.data.status) {
            console.log("success");
            window.location.reload();
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    const handleUnAssigned = (event) => {
      event.preventDefault();

      const d = new Date().toString();
      const i = d.indexOf(" GMT");
      const date = d.slice(0, i);

      const history = [...row.history, { date: date, status: "un-assigned" }];

      setLoading(true);
      axios
        .post("/complaint/update", {
          id: row.id,
          status: "un-assigned",
          history: history,
        })
        .then((res) => {
          if (res.data.status) {
            console.log("success");
            window.location.reload();
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    };

    const dispatch = useDispatch();

    const users = useSelector((state) => state.users.usersData);

    React.useEffect(() => {
      dispatch(getUsers("user"));
      return () => {};
    }, []);

    let h = row.history.filter((r) => r.status === "closed");
    // if (h && h.length && h[0]) {
    //     // h = h[0].date
    //     console.log(h[0].date);
    // }
    // const d1 = new Date(row.history.filter(r => r.status === 'closed')[0].date)
    // const d1 = new Date('Wed Nov 25 2022 14:53:58 GMT+0530 (India Standard Time)')
    const d1 = new Date(h && h.length && h[0] && h[0].date);
    const d2 = new Date();
    //const d2 = new Date('Wed Nov 25 2022 14:53:58 GMT+0530 (India Standard Time)')
    // console.log("d1", d1);
    const days = Math.round(
      (d2.getTime() - d1.getTime()) / (1000 * 60 * 60 * 24)
    );

    let selected = "";

    if (users.status) {
      users.data.forEach((element) => {
        usersData.push({ label: element.email, id: element._id });
        if (form.userId === element._id) {
          selected = element.email;
        }
      });
    }

    const theme = createTheme();

    const style = {
      position: "absolute",
      top: "30%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      // border: "2px solid #000",
      boxShadow: 24,
      p: 4,
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
      setOpen(true);
      setRemark("");
    };
    const handleClose1 = () => {
      setOpen(false);
      setRemark("");
    };

    useEffect(() => {
      const states = State.getStatesOfCountry("IN");

      const data = [];
      const data1 = [];

      states.forEach((e) => {
        data.push({
          label: e.name,
          code: e.isoCode,
        });
        if (e.name === form.state) {
          const cities = City.getCitiesOfState("IN", e.isoCode);
          data1.push({
            label: "Not in the List",
          });
          cities.forEach((el) => {
            data1.push({
              label: el.name,
            });
            if (e.name === form.city) {
              setShow(false);
            }
          });
        }
      });
      // console.log(data1);
      setCities(data1);
      setStates(data);

      return () => {};
    }, []);

    return (
      <ThemeProvider theme={theme}>
        <Modal
          open={open}
          onClose={handleClose1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {/* {row.status === "pending" ? "Un-Assign Service" : "Close Service"} */}
              {/* {row.status === "un-assigned" && "Mark as Pending Service"}
              {(row.status === "assigned" ||
                row.status === "re-opened" ||
                row.status === "remarked") &&
                "Close Service"} */}
              {row.status === "closed" && "Re-Open Service"}
            </Typography>
            <br />
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                id="remarks"
                label="Remarks"
                name="remarks"
                autoComplete="remarks"
                multiline
                minRows={4}
                maxRows={4}
                onChange={(e) => {
                  setRemark(e.target.value);
                }}
              />
              {!remark && (
                <Stack sx={{ width: "100%" }} spacing={2}>
                  <Alert severity="error">{"Please add remarks"}!</Alert>
                </Stack>
              )}
            </Grid>
            <br />
            <Button
              onClick={() => {
                if (remark && remark.length) {
                  // if (row.status === "un-assigned") {
                  //   handlePending();
                  // }
                  // if (
                  //   row.status === "assigned" ||
                  //   row.status === "re-opened" ||
                  //   row.status === "remarked"
                  // ) {
                  //   handleClose();
                  // }
                  if (row.status === "closed") {
                    handleReOpen();
                  }
                }
              }}
              variant="contained"
              size="medium"
              disabled={loading ? true : false}
            >
              {loading && (
                <CircularProgress
                  size={25}
                  sx={{
                    color: "white",
                  }}
                />
              )}
              Submit
            </Button>
          </Box>
        </Modal>

        <Typography component="h1" variant="h5">
          Edit Service
        </Typography>
        <hr
          style={{
            marginLeft: "0px",
            marginTop: "15px",
            width: "10%",
          }}
        />
        {/* {row.status !== 'closed' &&
                    <IconButton
                        onClick={() => handleDelete()}
                        aria-label="delete" size="large">
                        {!loading ?
                            <DeleteIcon sx={{
                                color: "red",
                            }} fontSize="inherit" />
                            :
                            <CircularProgress
                                size={25}
                            />
                        }
                    </IconButton>
                } */}
        {/* {row.status === 'pending' &&
                    <Button
                        onClick={handleUnAssigned}
                        variant="contained" size="medium"
                        disabled={loading ? true : false}
                    >
                        {loading &&
                            <CircularProgress
                                size={25}
                                sx={{
                                    color: 'white'
                                }}
                            />
                        }
                        Un-Assign Service
                    </Button>
                } */}
        {/* {
                    (row.status === 'assigned' || row.status === 're-opened') &&
                    <Button
                        onClick={handleClose}
                        variant="contained" size="medium"
                        disabled={loading ? true : false}
                    >
                        {loading &&
                            <CircularProgress
                                size={25}
                                sx={{
                                    color: 'white'
                                }}
                            />
                        }
                        Close Service
                    </Button>
                } */}
        {row.status !== "closed" && (
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
              sx={{
                marginTop: 8,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 2 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      disabled
                      id="combo-box-demo"
                      options={usersData}
                      onChange={(e, value) => {
                        setForm({ ...form, userId: value.id });
                        onChange({ name: "userId", value: value.id });
                      }}
                      renderInput={(params) => (
                        <TextField required {...params} label="User Id/Email" />
                      )}
                    />
                    <Typography
                      sx={{
                        color: "green",
                        mt: 1,
                        mb: 1,
                      }}
                    >
                      {selected}
                    </Typography>
                    {errors.userId && (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error">{errors.userId}!</Alert>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="name"
                      label="Name"
                      name="name"
                      autoComplete="name"
                      onChange={(e) => {
                        setForm({ ...form, name: e.target.value });
                        onChange({ name: "name", value: e.target.value });
                      }}
                      value={form.name}
                    />
                    {errors.name && (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error">{errors.name}!</Alert>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="description"
                      label="Description"
                      name="description"
                      autoComplete="description"
                      multiline
                      onChange={(e) => {
                        setForm({ ...form, description: e.target.value });
                        onChange({
                          name: "description",
                          value: e.target.value,
                        });
                      }}
                      value={form.description}
                    />
                    {errors.description && (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error">{errors.description}!</Alert>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="model_number"
                      label="Model Number"
                      name="model_number"
                      autoComplete="model_number"
                      onChange={(e) => {
                        setForm({ ...form, model_number: e.target.value });
                        onChange({
                          name: "model_number",
                          value: e.target.value,
                        });
                      }}
                      value={form.model_number}
                    />
                    {errors.model_number && (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error">{errors.model_number}!</Alert>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      id="serial_number"
                      label="Serial Number"
                      name="serial_number"
                      autoComplete="serial_number"
                      onChange={(e) => {
                        setForm({ ...form, serial_number: e.target.value });
                        onChange({
                          name: "serial_number",
                          value: e.target.value,
                        });
                      }}
                      value={form.serial_number}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="mobile"
                      label="Mobile"
                      name="mobile"
                      autoComplete="mobile"
                      onChange={(e) => {
                        setForm({ ...form, mobile: e.target.value });
                        onChange({ name: "mobile", value: e.target.value });
                      }}
                      value={form.mobile}
                    />
                    {errors.mobile && (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error">{errors.mobile}!</Alert>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={states}
                      onChange={(e, value) => {
                        setForm({ ...form, state: value.label });
                        onChange({ name: "state", value: value.label });
                        const cities = City.getCitiesOfState("IN", value.code);
                        const data = [
                          {
                            label: "Not in the List",
                          },
                        ];
                        cities.forEach((e) => {
                          data.push({
                            label: e.name,
                          });
                        });
                        setCities(data);
                      }}
                      renderInput={(params) => (
                        <TextField required {...params} label="State" />
                      )}
                      value={form.state}
                    />
                    {errors.state && (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error">{errors.state}!</Alert>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={cities}
                      onChange={(e, value) => {
                        if (value.label !== "Not in the List") {
                          setForm({ ...form, city: value.label });
                          onChange({ name: "city", value: value.label });
                          setShow(false);
                        } else {
                          setShow(true);
                          setForm({ ...form, city: "" });
                        }
                      }}
                      renderInput={(params) => (
                        <TextField required {...params} label="City" />
                      )}
                      value={show ? "Not in the List" : form.city}
                    />
                    {errors.city && (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error">{errors.city}!</Alert>
                      </Stack>
                    )}
                  </Grid>
                  {show && (
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        value={form.city}
                        id="city"
                        label="City"
                        name="city"
                        autoComplete="city"
                        multiline
                        maxRows={4}
                        onChange={(e) => {
                          setForm({ ...form, city: e.target.value });
                          onChange({ name: "city", value: e.target.value });
                        }}
                      />
                      {errors.city && (
                        <Stack sx={{ width: "100%" }} spacing={2}>
                          <Alert severity="error">{errors.city}!</Alert>
                        </Stack>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="address"
                      label="Address"
                      name="address"
                      autoComplete="address"
                      multiline
                      maxRows={4}
                      onChange={(e) => {
                        setForm({ ...form, address: e.target.value });
                        onChange({ name: "address", value: e.target.value });
                      }}
                      value={form.address}
                    />
                    {errors.address && (
                      <Stack sx={{ width: "100%" }} spacing={2}>
                        <Alert severity="error">{errors.address}!</Alert>
                      </Stack>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>Upload images</Typography>
                    <br />
                    <Button variant="contained" component="label">
                      Upload
                      <input
                        hidden
                        accept="image/*"
                        multiple
                        type="file"
                        onChange={onImageUpload}
                      />
                    </Button>
                    <ImageList sx={{ Width: 330, maxHeight: 350 }}>
                      {imageUrls.map((item, key) => (
                        <ImageListItem key={key}>
                          <img
                            src={item}
                            // srcSet={`https://www.w3schools.com/css/img_forest.jpg`}
                            alt={"image"}
                            loading="lazy"
                          />
                          <ImageListItemBar
                            title={"Remove"}
                            sx={{
                              height: 30,
                              cursor: "pointer",
                              background: "gray",
                              color: "red",
                              fontWeight: "700",
                            }}
                            onClick={() => {
                              const newArr = [...imageUrls];
                              newArr.splice(key, 1);
                              setImageUrls(newArr);
                            }}
                            actionIcon={
                              <IconButton sx={{ color: "orangered" }}>
                                <DeleteIcon />
                              </IconButton>
                            }
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={loading ? true : false}
                >
                  {loading ? (
                    <CircularProgress
                      sx={{
                        color: "white",
                      }}
                      size={25}
                    />
                  ) : null}
                  Edit
                </Button>
              </Box>
            </Box>
          </Container>
        )}

        {row.status === "closed" && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                color: "gray",
                mb: 5,
                mt: 3,
              }}
            >
              You can Re-Open the Service with in 30 Days
            </Typography>
            <Button
              onClick={handleOpen}
              variant="contained"
              size="medium"
              disabled={loading || days > 30 ? true : false}
            >
              {loading && (
                <CircularProgress
                  size={25}
                  sx={{
                    color: "white",
                  }}
                />
              )}
              Re-Open
            </Button>
          </div>
        )}
      </ThemeProvider>
    );
  };

  const MoreDetails = () => {
    let user = [];

    const dispatch = useDispatch();

    const users = useSelector((state) => state.users.usersData);

    React.useEffect(() => {
      dispatch(getUsers("user"));
      return () => {};
    }, []);

    if (users.status) {
      users.data.forEach((e) => {
        if (row.userId === e._id) {
          user.push(e);
        }
      });
    }

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: "auto" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">State</TableCell>
              <TableCell align="left">City</TableCell>
              <TableCell align="left">Description</TableCell>
              <TableCell align="left">Model Number</TableCell>
              <TableCell align="left">Serial Number</TableCell>
              <TableCell align="left"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell align="left">{row.state}</TableCell>
              <TableCell align="left">{row.city}</TableCell>
              <TableCell align="left">{row.description}</TableCell>
              <TableCell align="left">{row.model_number}</TableCell>
              <TableCell align="left">{row.serial_number}</TableCell>
              <TableCell align="left">
                <PDF data={row} user={user ? user[0] : []} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const Remarks = () => {
    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: "auto" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left">Date</TableCell>
              <TableCell align="left">Remark</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {row.remarks.map((remark) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{remark.date}</TableCell>
                <TableCell align="left">{remark.remark}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const data = [
    {
      label: "History",
      title: "Complaint History",
      component: <HistoryComponent />,
    },
    {
      label: "Assigned Engineer",
      title: "Engineer Details",
      component: <EngineerTable />,
    },
    {
      label: "Edit Service",
      title: "Edit Service Details",
      component: <EditComplaint />,
    },
    {
      label: "More Details",
      title: "More Details",
      component: <MoreDetails />,
    },
    // {
    //   label: "Remarks",
    //   title: "Remarks",
    //   component: <Remarks />,
    // },
  ];

  return (
    <React.Fragment>
      {/* <tr>
                <td colSpan={6}>
                    <div style={{
                        display: 'flex',
                        margin: "20px 0",
                        justifyContent: 'center'
                    }}>
                        <Button variant="contained" size="small" endIcon={<SendIcon />}>
                            Click to Assign This Complaint
                        </Button>
                    </div>
                    <TableContainer component={Paper}>
                        <Table style={{ minWidth: "650" }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Dessert (100g serving)</TableCell>
                                    <TableCell align="right">Calories</TableCell>
                                    <TableCell align="right">Fat&nbsp;(g)</TableCell>
                                    <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                                    <TableCell align="right">Calories</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, key) => (
                                    <TableRow key={key}>
                                        <TableCell component="th" scope="row">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="right">{row.calories}</TableCell>
                                        <TableCell align="right">{row.fat}</TableCell>
                                        <TableCell align="right">{row.carbs}</TableCell>
                                        <TableCell align="right">{row.protein}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </td>
            </tr> */}

      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell padding="checkbox">
          <Checkbox
            // onClick={(event) => handleClick(event, row.name)}
            onClick={onClick}
            color="primary"
            checked={isItemSelected}
            inputProps={{
              "aria-labelledby": labelId,
            }}
          />
        </TableCell>
        <TableCell component="th" scope="row">
          ITWALESB{row.id.slice(-6)}
        </TableCell>
        <TableCell align="left">{row.name}</TableCell>
        {/* <TableCell align="left">
          {row.description.length <= 20
            ? row.description
            : row.description.slice(0, 20) + "..."}
        </TableCell>
        <TableCell align="left">{row.model_number}</TableCell>
        <TableCell align="left">
          {row.serial_number ? row.serial_number : "None"}
        </TableCell> */}
        <TableCell align="left">{row.mobile}</TableCell>
        <TableCell align="left">{row.address}</TableCell>
        <TableCell
          align="left"
          sx={{
            width: "20%",
            cursor: "pointer",
          }}
        >
          <div
            style={{
              background: setBg(row.status),
              textAlign: "center",
              padding: "5px",
              borderRadius: "15px",
              color: "white",
              fontWeight: "700",
            }}
          >
            {row.status.toUpperCase()}
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{
              margin: "25px auto",
            }}
          >
            <SubLabTabs data={data} />
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function ComplaintsTable({ title, status }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rows, setRows] = React.useState([]);
  const [copyRows, setCopyRows] = React.useState([]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      ids = newSelected;
      return;
    }
    setSelected([]);
    ids = [];
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
    ids = newSelected;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const userId =
    localStorage.getItem("user") &&
    JSON.parse(localStorage.getItem("user")) &&
    JSON.parse(localStorage.getItem("user"))._id;

  const getData = () => {
    axios
      .post("/complaint/getallcomplaints", {
        status: status,
        type: "user",
        userId: userId,
      })
      .then((res) => {
        // console.log(res);
        const data = [];
        res.data.data.forEach((e) => {
          data.push(
            createData(
              e._id,
              e.name,
              e.description,
              e.model_number,
              e.serial_number,
              e.mobile,
              e.address,
              e.status,
              e.history,
              e.engineer,
              e.images,
              e.userId,
              e.remarks,
              e.state,
              e.city,
              e.price
            )
          );
        });
        setRows(data);
        setCopyRows(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
  }, [status, userId]);

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          title={title}
          originalRows={copyRows}
          setRows={setRows}
        />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    // <TableRow
                    //     hover
                    //     role="checkbox"
                    //     // aria-checked={isItemSelected}
                    //     tabIndex={-1}
                    //     key={row.name}
                    // // selected={isItemSelected}
                    // >
                    //     <TableCell padding="checkbox">
                    //         <Checkbox
                    //             onClick={(event) => handleClick(event, row.name)}
                    //             color="primary"
                    //             checked={isItemSelected}
                    //             inputProps={{
                    //                 'aria-labelledby': labelId,
                    //             }}
                    //         />
                    //     </TableCell>
                    //     <TableCell
                    //         component="th"
                    //         id={labelId}
                    //         scope="row"
                    //         padding="none"
                    //     >
                    //         {row.name}
                    //     </TableCell>
                    //     <TableCell align="right">{row.calories}</TableCell>
                    //     <TableCell align="right">{row.fat}</TableCell>
                    //     <TableCell align="right">{row.carbs}</TableCell>
                    //     <TableCell align="right">{row.protein}</TableCell>
                    // </TableRow>

                    <CollapseRow
                      row={row}
                      key={index}
                      isItemSelected={isItemSelected}
                      labelId={labelId}
                      onClick={(event) => handleClick(event, row.id)}
                    />
                  );
                })}
              {/* {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (53) * emptyRows,
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
