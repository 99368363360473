import React from 'react'
import UsersTable from '../components/UsersTable'

function Engineers() {

    return (
        <div>
            <UsersTable title={'Engineers'} role={'engineer'} />
        </div>
    )
}

export default Engineers